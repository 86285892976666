import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from './components/Home/Home';
import Login from "./components/Login/Login";
// import SignUp from "./components/SignUp/Signup";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import PasswordReset from "./components/Password Reset/PasswordReset";
import AddQuestion from "./components/AddQuestion/AddQuestion";
import Stats from "./components/Stats/Stats";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy/CookiePolicy";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import { UserProvider } from "./Contexts/UserProvider";
import Subscriptions from "./components/Subscriptions/Subscriptions";
import { UserContext } from "./Contexts/UserProvider";
import Cookies from "js-cookie";
import React, { Component, useContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import StartQuiz from "./components/StartQuiz/StartQuiz";
import Quiz from "./components/Quiz/Quiz"
import AddSubCategory from "./components/AddSubCategory/AddSubCategory";
import SubCategories from "./components/SubCategories/SubCategories";
import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import NotFound from "./components/NotFound/NotFound";
//import Category from './Components/category/category';

const AppContent = () => {
  const { loading } = useContext(UserContext);
  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const handleBuyClick = (amount, subscriptionType) => {
    setSelectedSubscription({ amount, subscriptionType });
    setShowCheckout(true);
  };

  const [expanded, setExpanded] = useState(false);

  const handleToggle = (e) => {
    setExpanded(e);
    // console.log(expanded);
  };

  if (loading) {
    return (
      <div className="loader-main">
        <div className="loader">
          <svg viewBox="0 0 80 80">
            <circle r="32" cy="40" cx="40" id="test"></circle>
          </svg>
        </div>

        <div className="loader triangle">
          <svg viewBox="0 0 86 80">
            <polygon points="43 8 79 72 7 72"></polygon>
          </svg>
        </div>

        <div className="loader">
          <svg viewBox="0 0 80 80">
            <rect height="64" width="64" y="8" x="8"></rect>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Header expanded={expanded} handleToggle={handleToggle} />
      <div onClick={() => handleToggle(false)}>
        <Routes>
          <Route path="/" element={<Home handleBuyClick={handleBuyClick} />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/resetPassword/:token" element={<PasswordReset />} />
          <Route path="/addQuestion" element={<AddQuestion />} />
          <Route path="/addSubCategory" element={<AddSubCategory />} />
          <Route path="/SubCategories" element={<SubCategories />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/performance" element={<Stats />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/jewels" element={<ComingSoon />} />
          <Route path="/flashcards" element={<ComingSoon />} />
          <Route path="/subscriptions" element={<Subscriptions showCheckout={showCheckout} selectedSubscription={selectedSubscription} />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <div className="App">
      <UserProvider>
        <AppContent />
      </UserProvider>
    </div>
  );
}

export default App;
