import React, { useState, useRef } from 'react'
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

function ContactUs() {
    const [captchaValue, setCaptchaValue] = useState(null);
    const [sendingMessage, setSendingMessage] = useState(false);
    const recaptchaRef = useRef();
    const [err, setErr] = useState("");
    const [message, setMessage] = useState("");
    const [contactUsForm, setContactUsForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        description: '',
        // attachment: ''
    });
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            // Handle file input separately to support multiple files
            const fileArray = Array.from(files);
            setContactUsForm((prev) => ({
                ...prev,
                [name]: fileArray[0],
            }));
        } else {
            // Handle other input types
            setContactUsForm((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setErr("");
        if (!captchaValue) {
            setErr("Please verify that you are not a robot");
            return;
        }

        if (
            contactUsForm.firstName === "" ||
            contactUsForm.email === "" ||
            contactUsForm.description === ""//  ||
            // contactUsForm.attachment === ""
        ) {
            setErr("Please fill all the required fields");
            return;
        }

        try {
            setSendingMessage(true);
            // const formData = new FormData();
            // formData.append('image', contactUsForm.attachment);
            // const imageAddress = await axios.post('https://api.reviseukmla.com/api/v1/image/upload-image', formData, {
            //     headers: {
            //         "Content-Type": "multipart/form-data",
            //         "captcha": captchaValue
            //     }
            // });
            // setContactUsForm({
            //     ...contactUsForm,
            //     attachments: [{
            //         filename: imageAddress.data.imageUrl.split('/')[imageAddress.data.imageUrl.split('/').length - 1],
            //         path: imageAddress.data.imageUrl
            //     }]
            // });
            const q = {
                ...contactUsForm,
                captcha: captchaValue
            }
            // console.log(q);
            const response = await axios.post(
                "https://api.reviseukmla.com/api/v1/users/contactUs",
                q,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                setMessage("Message sent successfully");
            } else {
                setErr("Failed to send message");
            }
        } catch (error) {
            // console.log(error);
            setErr(error.response.data.message);
        }
        setSendingMessage(false);
    }
    return (
        <div>
            <Container>
                <Row className="justify-content-md-center mt-5">
                    <Col md={{ span: 6 }}>
                        <h2 className='text-center'>Contact Us</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md={{ span: 6 }}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="firstName" className=' mb-3'>
                                <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    value={contactUsForm.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="lastName" className=' mb-3'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    placeholder="Enter your last name"
                                    value={contactUsForm.lastName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="email" className=' mb-3'>
                                <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={contactUsForm.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="email" className=' mb-3'>
                                <Form.Label>Description <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    placeholder="Description"
                                    value={contactUsForm.description}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            {/* <Form.Group controlId="email" className=' mb-3'>
                                <Form.Label>Attachments <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="file"
                                    name="attachment"
                                    accept=".jpg, .jpeg, .png"
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group> */}
                            <ReCAPTCHA className='customcaptcha'
                                ref={recaptchaRef}
                                sitekey="6Le3dhAqAAAAAP9ZLjG_e4iZDZpOIvbnh2Pu4pLQ"
                                onChange={value => setCaptchaValue(value)}
                                onExpired={() => setCaptchaValue(null)}
                            />
                            <Button
                                className="custombutton mb-3"
                                type="submit"
                            >
                                Contact Us
                            </Button>
                            {err !== "" ? (
                                <Alert key="danger" variant="danger">
                                    {err}
                                </Alert>
                            ) : null}
                            {message !== "" ? (
                                <Alert key="success" variant="success">
                                    {message}
                                </Alert>
                            ) : null}
                        </Form>
                        {sendingMessage && <div className="spinner-border text-primary spinner" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default ContactUs