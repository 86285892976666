import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Alert, Container, Row, Col, Button, Spinner, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { PieChart } from '@mui/x-charts/PieChart';

import "./Quiz.css";

const Quiz = () => {
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [submittedAnswers, setSubmittedAnswers] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [finishTest, setFinishTest] = useState(false);
  const [showBackToRevision, setShowBackToRevision] = useState(true);
  const [remainingTime, setRemainingTime] = useState(0);
  const [topics, setTopics] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedQuestions = JSON.parse(localStorage.getItem("questions"));
    const storedAnswers = JSON.parse(localStorage.getItem("selectedOptions"));
    const storedSubmittedAnswers = JSON.parse(localStorage.getItem("submittedAnswers"));
    const storedCorrectAnswers = JSON.parse(localStorage.getItem("correctAnswers"));
    const storedTopics = JSON.parse(localStorage.getItem("topics"));
    if (storedQuestions) {
      setQuestions(storedQuestions);
      setSelectedOptions(storedAnswers || Array(storedQuestions.length).fill(null));
      setSubmittedAnswers(storedSubmittedAnswers || Array(storedQuestions.length).fill(0));
      setCorrectAnswers(storedCorrectAnswers || Array(storedQuestions.length).fill(""));
      setTopics(storedTopics || Array(storedQuestions.length).fill(null));
      // window.location.href = "/quiz/#quiz";
    } else {
      // If no questions are found in local storage, navigate back to start quiz
      localStorage.removeItem("questions");
      localStorage.removeItem("selectedOptions");
      localStorage.removeItem("submittedAnswers");
      localStorage.removeItem("topics");
      localStorage.removeItem("timerStart");
      localStorage.removeItem("countdownDuration");
      localStorage.removeItem("correctAnswers");
      window.location.href = "/subscriptions";
    }

    const savedTime = localStorage.getItem('timerStart');
    if (savedTime) {
      const elapsedTime = (Date.now() - parseInt(localStorage.getItem('timerStart'), 10)) / 1000;
      const newRemainingTime = parseInt(localStorage.getItem("countdownDuration") || "0") - elapsedTime + 1;
      // console.log(newRemainingTime)
      if (newRemainingTime > 0) {
        setRemainingTime(newRemainingTime);
      } else {
        setRemainingTime(0);
      }
    } else {
      localStorage.setItem('timerStart', Date.now().toString());
    }

    const timerInterval = setInterval(() => {
      setRemainingTime((prevTime) => {
        const newPrevTime = prevTime > 0 ? prevTime - 1 : 0;
        if (newPrevTime === 1) {
          setShowBackToRevision(false);
          finishQuiz();
        }
        return newPrevTime;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    if (Math.floor(remainingTime) === 2) {
      setShowBackToRevision(false);
      setFinishTest(true);
    }
  }, [remainingTime]); // This effect runs whenever remainingTime changes

  const handleOptionChange = (questionIdx, answer) => {
    const updatedOptions = [...selectedOptions];
    if (submittedAnswers[selectedQuestion] === 0) {
      updatedOptions[questionIdx] = answer;
    }
    setSelectedOptions(updatedOptions);
    localStorage.setItem("selectedOptions", JSON.stringify(updatedOptions));
  };

  const handleSubmit = async (e) => {
    setMessage("");
    setErr("");
    setLoading(true);
    const token = Cookies.get("token"); // Get token from cookie
    const answer = {
      questionId: questions[selectedQuestion]._id,
      submittedAnswer: selectedOptions[selectedQuestion],
    };
    let response;
    if (!token) {
      response = await axios.post("https://api.reviseukmla.com/api/v1/quiz/submitTrialAnswer",
        answer,
        {
          headers: {
            "Content-Type": "application/json",
          }
        });
    } else {
      response = await axios.post("https://api.reviseukmla.com/api/v1/quiz/submitAnswer",
        answer,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        });
    }

    const updatedSubmittedAnswers = [...submittedAnswers];
    setLoading(false);
    if (response.data.isCorrect) {
      updatedSubmittedAnswers[selectedQuestion] = 2;
      // setMessage("Correct answer");
    } else {
      updatedSubmittedAnswers[selectedQuestion] = 1;
      // setErr("Incorrect answer");
    }
    const updatedCorrectAnswers = [...correctAnswers];
    updatedCorrectAnswers[selectedQuestion] = response.data.correctAnswer;
    const updatedQuestions = [...questions];
    updatedQuestions[selectedQuestion].explanation = response.data.explanation;
    setSubmittedAnswers(updatedSubmittedAnswers);
    setCorrectAnswers(updatedCorrectAnswers);
    setQuestions(updatedQuestions);
    const updatedTopics = [...topics];
    updatedTopics[selectedQuestion] = response.data.topic;
    setTopics(updatedTopics);
    localStorage.setItem("submittedAnswers", JSON.stringify(updatedSubmittedAnswers));
    localStorage.setItem("correctAnswers", JSON.stringify(updatedCorrectAnswers));
    localStorage.setItem("questions", JSON.stringify(updatedQuestions));
    localStorage.setItem("topics", JSON.stringify(updatedTopics));
  }

  const finishQuiz = () => {
    setFinishTest(true);
  }

  const getClass = (idx) => {
    let className = "";
    if (selectedQuestion === idx) {
      className = "selected-question";
    }
    if (submittedAnswers[idx]) {
      if (submittedAnswers[idx] === 2) {
        className += " correct-answer";
      } else if (submittedAnswers[idx] === 1) {
        className += " wrong-answer";
      }
    }
    return className
  }

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    let formattedTime = '';
    if (hours > 0) {
      formattedTime += hours + ' hr ';
    }
    if (minutes > 0) {
      formattedTime += minutes + ' min ';
    }
    if (remainingSeconds > 0 || (hours === 0 && minutes === 0)) {
      formattedTime += remainingSeconds + ' sec ';
    }

    return formattedTime.trim();
  };
  const filterData = (data) => {
    const filteredData = data.filter((item, idx) => item.value !== 0)
    return filteredData;
  }

  function decodedHtml(html) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  }

  const createMarkup = (htmlString) => {
    return { __html: decodedHtml(decodedHtml(htmlString)) };
  };

  return (
    <div>
      {finishTest === true ? (
        <div className='container login-main stats-main' >
          <h2>Result for this session</h2>
          <div className='pie-chart mt-0 mb-0'>

            {(questions.length) ?

              <PieChart
                colors={filterData([{
                  id: 0,
                  value: submittedAnswers.filter(answer => answer === 2).length,
                  label: 'Correct',
                  color: 'green'
                }, {
                  id: 1,
                  value: submittedAnswers.filter(answer => answer === 1).length,
                  label: 'Incorrect',
                  color: 'red'
                }, {
                  id: 2,
                  value: questions.length - submittedAnswers.filter(answer => answer === 2).length - submittedAnswers.filter(answer => answer === 1).length,
                  label: 'Unanswered',
                  color: 'blue'
                }]).map(item => item.color)} // Use palette
                series={[
                  {
                    data: filterData([{
                      id: 0,
                      value: submittedAnswers.filter(answer => answer === 2).length,
                      label: 'Correct',
                      color: 'green'
                    }, {
                      id: 1,
                      value: submittedAnswers.filter(answer => answer === 1).length,
                      label: 'Incorrect',
                      color: 'red'
                    }, {
                      id: 2,
                      value: questions.length - submittedAnswers.filter(answer => answer === 2).length - submittedAnswers.filter(answer => answer === 1).length,
                      label: 'Unanswered',
                      color: 'blue'
                    }]),
                    innerRadius: 80,
                    paddingAngle: 1,
                    highlightScope: {
                      faded: 'global'
                    }
                  }
                ]}
                slotProps={{ legend: { hidden: true } }}
                width={400}
                height={300}
                margin={{ right: 0 }}
              >
              </PieChart> : <div></div>}
          </div>
          <Container id="quiz" className="quiz-page-container">
            <Row>
              <Col md={7}>
                <div className="question-answers-description">
                  {questions.length > 0 ? (
                    <div key={selectedQuestion} className="question-container">
                      <div className="question">
                        <h3 className="mb-4">{selectedQuestion + 1} of {questions.length} {remainingTime > 0 && <span style={{ float: "right" }}>{formatTime(remainingTime)}</span>}</h3>
                        <h5 dangerouslySetInnerHTML={createMarkup(questions[selectedQuestion].statement)}></h5>
                      </div>
                      <div className="options">
                        {questions[selectedQuestion].answers.map((option, idx) => (
                          <div className={"checkbox-wrapper-46".concat(submittedAnswers[selectedQuestion] === 1 && selectedOptions[selectedQuestion] === option.answer ? " answer-incorrect" : (correctAnswers[selectedQuestion] === option.answer ? " answer-correct" : (submittedAnswers[selectedQuestion] === 0 ? " answer-option" : " answer-disabled")))} key={idx} onClick={() => handleOptionChange(selectedQuestion, option.answer)}>
                            <input
                              type="checkbox"
                              id={`option-${selectedQuestion}-${idx}`}
                              className="inp-cbx"
                              value={decodedHtml(option.answer)}
                              onChange={() => handleOptionChange(selectedQuestion, option.answer)}
                              checked={selectedOptions[selectedQuestion] === option.answer}
                              disabled={true}
                            />
                            <label htmlFor={`option-${selectedQuestion}-${idx}`} className="cbx">
                              <span>
                                <svg viewBox="0 0 12 10" height="10px" width="12px">
                                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                </svg>
                              </span>
                              <span>{option.answer}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                  <div className="control-buttons d-flex justify-content-between mb-3">
                    <button
                      className="btn btn-primary navigate-question prev-question"
                      onClick={() => selectedQuestion > 0 && setSelectedQuestion(selectedQuestion - 1)}
                      disabled={selectedQuestion === 0}
                    >
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>

                    <button
                      className="btn btn-primary navigate-question next-question"
                      onClick={() => selectedQuestion < questions.length - 1 && setSelectedQuestion(selectedQuestion + 1)}
                      disabled={submittedAnswers[selectedQuestion] === 0 || selectedQuestion === questions.length - 1}
                    >
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  {/* <div className="control-buttons d-flex justify-content-between">
                    <button
                      className="navigate-question prev-question"
                      onClick={() => selectedQuestion > 0 && setSelectedQuestion(selectedQuestion - 1)}
                      disabled={selectedQuestion === 0}
                    >
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>
                    <button
                      className="navigate-question next-question"
                      onClick={() => selectedQuestion < questions.length - 1 && setSelectedQuestion(selectedQuestion + 1)}
                      disabled={selectedQuestion === questions.length - 1}
                    >
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    </button>
                  </div> */}
                  {submittedAnswers[selectedQuestion] !== 0 && questions[selectedQuestion] ? <div dangerouslySetInnerHTML={createMarkup("<div class=\"text-decoration-underline\">Explanation</div>" + questions[selectedQuestion].explanation)}></div> : null}
                  <br />
                  {topics[selectedQuestion] && <div className="topic-essay" dangerouslySetInnerHTML={createMarkup(`<h1>${topics[selectedQuestion].name}</h1>` + topics[selectedQuestion].description)}></div>}
                </div>
              </Col>
              <Col md={5} className="quiz-right">
                {(submittedAnswers.includes(1) || submittedAnswers.includes(2)) && (
                  <div className="quiz-buttons d-flex flex-wrap">
                    {questions.map((question, idx) => (
                      <React.Fragment key={idx}>
                        <button className={getClass(idx)} onClick={() => setSelectedQuestion(submittedAnswers[idx] !== 0 ? idx : selectedQuestion)}>
                          {idx + 1}
                        </button>
                        {(idx + 1) % 5 === 0 && <br />}
                      </React.Fragment>
                    ))}
                  </div>
                )}
                {submittedAnswers[selectedQuestion] === 2 && <Alert variant="success">Correct answer</Alert>}
                {submittedAnswers[selectedQuestion] === 1 && <Alert variant="danger">Incorrect answer</Alert>}
                <div className="flex-center">
                  {finishTest ? (showBackToRevision && <button className="btn btn-primary submit-answer" onClick={() => setFinishTest(false)}>Back to revision</button>) : (
                    <button className="btn btn-primary submit-answer" onClick={finishQuiz}>Finish test</button>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>) : (
        <Container id="quiz" className="quiz-page-container">
          <Row>
            <Col md={12}>
              <div className="question-answers-description">
                {questions.length > 0 ? (
                  <div key={selectedQuestion} className="question-container">
                    <div className="question">
                      <h3 className="mb-4">{selectedQuestion + 1} of {questions.length} {remainingTime > 0 && <span style={{ float: "right" }}>{formatTime(remainingTime)}</span>}</h3>
                      <h5 dangerouslySetInnerHTML={createMarkup(questions[selectedQuestion].statement)}></h5>
                    </div>
                    <div className="options">
                      {questions[selectedQuestion].answers.map((option, idx) => (
                        <div className={"checkbox-wrapper-46".concat(submittedAnswers[selectedQuestion] === 1 && selectedOptions[selectedQuestion] === option.answer ? " answer-incorrect" : (correctAnswers[selectedQuestion] === option.answer ? " answer-correct" : (submittedAnswers[selectedQuestion] === 0 ? " answer-option" : " answer-disabled")))} key={idx} onClick={() => handleOptionChange(selectedQuestion, option.answer)}>
                          <input
                            type="checkbox"
                            id={`option-${selectedQuestion}-${idx}`}
                            className="inp-cbx"
                            value={decodedHtml(option.answer)}
                            onChange={() => handleOptionChange(selectedQuestion, option.answer)}
                            checked={selectedOptions[selectedQuestion] === option.answer}
                            disabled={true}
                          />
                          <label htmlFor={`option-${selectedQuestion}-${idx}`} className="cbx">
                            <span>
                              <svg viewBox="0 0 12 10" height="10px" width="12px">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>{option.answer}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Spinner animation="border" variant="primary" />
                )}
                <div className="control-buttons d-flex justify-content-between mb-3">
                  <button
                    className="btn btn-primary navigate-question prev-question"
                    onClick={() => selectedQuestion > 0 && setSelectedQuestion(selectedQuestion - 1)}
                    disabled={selectedQuestion === 0}
                  >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  </button>
                  <button className="btn btn-primary submit-answer" onClick={handleSubmit} disabled={submittedAnswers[selectedQuestion] !== 0 || selectedOptions[selectedQuestion] === null}>Submit answer</button>

                  <button
                    className="btn btn-primary navigate-question next-question"
                    onClick={() => selectedQuestion < questions.length - 1 && setSelectedQuestion(selectedQuestion + 1)}
                    disabled={submittedAnswers[selectedQuestion] === 0 || selectedQuestion === questions.length - 1}
                  >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  </button>
                </div>
                <div className="quiz-right mb-3">
                  {loading && (
                    <Spinner animation="border" variant="primary" />
                  )}
                  {submittedAnswers[selectedQuestion] === 2 && <Alert variant="success">Correct answer</Alert>}
                  {submittedAnswers[selectedQuestion] === 1 && <Alert variant="danger">Incorrect answer</Alert>}
                </div>
                {submittedAnswers[selectedQuestion] !== 0 && !(remainingTime > 0) && questions[selectedQuestion] ? <div className="text-justify" dangerouslySetInnerHTML={createMarkup("<div class=\"text-decoration-underline fw-bold\">Explanation</div><p class=\"text-justify\">" + questions[selectedQuestion].explanation + "</p>")}></div> : null}
                <br />
                {topics[selectedQuestion] && !(remainingTime > 0) && <div className="topic-essay" dangerouslySetInnerHTML={createMarkup(`<h1>${topics[selectedQuestion].name}</h1>` + topics[selectedQuestion].description)}></div>}
              </div>
              <div className="flex-center">
                {finishTest ? (<button className="btn btn-primary submit-answer" onClick={() => setFinishTest(false)}>Back to revision</button>) : (
                  <button className="btn btn-primary submit-answer" onClick={finishQuiz}>Finish test</button>
                )}
              </div>
            </Col>

          </Row>
        </Container>)
      }

    </div >
  );
};

export default Quiz;
