import React from 'react'

import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <div className='container'>
            <h1 className='privacy-policy-heading'>Privacy Policy</h1>
            <div id="infocollect">
                <div id="infocollect"><span id="control"><strong><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong></span></div>
                <div></div>
                <div id="personalinfo"><span data-custom-class="heading_2"><strong>Personal information you disclose to us</strong></span></div>
                <div>
                    <div></div>
                    <div><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We collect personal information that you provide to us.</em></span></div>
                </div>
                <div></div>
                <div><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you&nbsp;register on the Services,&nbsp;</span><span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></div>
                <div></div>
                <div></div>
                <div><span data-custom-class="body_text"><strong>Personal Information Provided by You.</strong>&nbsp;The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</span></div>
                <ul>
                    <li><span data-custom-class="body_text">names</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">phone numbers</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">email addresses</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">usernames</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">passwords</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">contact preferences</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">contact or authentication data</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">billing addresses</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">debit/credit card numbers</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">mailing addresses</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text">job titles</span></li>
                </ul>
                <div></div>
                <div id="sensitiveinfo"><span data-custom-class="body_text"><strong>Sensitive Information.</strong>&nbsp;We do not process sensitive information.</span></div>
                <div></div>
                <div></div>
                <div><span data-custom-class="body_text"><strong>Payment Data.</strong>&nbsp;We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by&nbsp;Stripe&nbsp;. You may find their privacy notice link(s) here:&nbsp;<a href="https://stripe.com/en-gb/privacy/preview" target="_blank" rel="noopener noreferrer" data-custom-class="link">https://stripe.com/en-gb/privacy/preview</a>&nbsp;.</span></div>
                <div></div>
                <div></div>
                <div><span data-custom-class="body_text"><strong>Social Media Login Data.&nbsp;</strong>We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called&nbsp;‘<a href="#sociallogins" data-custom-class="link">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>&nbsp;‘&nbsp;below.</span></div>
                <div></div>
                <div></div>
                <div><span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></div>
                <div></div>
                <div></div>
                <div><span data-custom-class="heading_2"><strong>Information automatically collected</strong></span></div>
                <div>
                    <div></div>
                    <div><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</em></span></div>
                </div>
                <div></div>
                <div><span data-custom-class="body_text">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></div>
                <div></div>
                <div></div>
                <div><span data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar technologies.</span></div>
                <div></div>
                <div></div>
                <div><span data-custom-class="body_text">The information we collect includes:</span></div>
                <ul>
                    <li><span data-custom-class="body_text"><em>Log and Usage Data.</em>&nbsp;Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services&nbsp;(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called&nbsp;‘crash dumps’), and hardware settings).</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text"><em>Device Data.</em>&nbsp;We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</span></li>
                </ul>
                <div></div>
                <ul>
                    <li><span data-custom-class="body_text"><em>Location Data.</em>&nbsp;We collect location data such as information about your device’s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</span></li>
                </ul>
                <div></div>
                <div id="infouse"><span id="control"><strong><span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></span></div>
                <div>
                    <div></div>
                    <div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></span></div>
                </div>
                <div></div>
                <div><span data-custom-class="body_text"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></span></div>
                <ul>
                    <li><span data-custom-class="body_text"><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.</span></li>
                </ul>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <ul>
                                                    <li><span data-custom-class="body_text"><strong>To request feedback.&nbsp;</strong>We may process your information when necessary to request feedback and to contact you about your use of our Services.</span></li>
                                                </ul>
                                                <div>
                                                    <div></div>
                                                    <ul>
                                                        <li><span data-custom-class="body_text"><strong>To send you marketing and promotional communications.&nbsp;</strong>We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see&nbsp;‘&nbsp;</span><a href="#privacyrights" data-custom-class="link"><span data-custom-class="body_text">WHAT ARE YOUR PRIVACY RIGHTS?</span></a><span data-custom-class="body_text">&nbsp;‘&nbsp;below).</span></li>
                                                    </ul>
                                                    <div>
                                                        <div></div>
                                                        <ul>
                                                            <li><span data-custom-class="body_text"><strong>To deliver targeted advertising to you.</strong>&nbsp;We may process your information to develop and display&nbsp;personalised&nbsp;content and advertising tailored to your interests, location, and more.</span></li>
                                                        </ul>
                                                        <div>
                                                            <div>
                                                                <div></div>
                                                                <ul>
                                                                    <li><span data-custom-class="body_text"><strong>To protect our Services.</strong>&nbsp;We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</span></li>
                                                                </ul>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div></div>
                                                                            <ul>
                                                                                <li><span data-custom-class="body_text"><strong>To identify usage trends.</strong>&nbsp;We may process information about how you use our Services to better understand how they are being used so we can improve them.</span></li>
                                                                            </ul>
                                                                            <div>
                                                                                <div></div>
                                                                                <ul>
                                                                                    <li><span data-custom-class="body_text"><strong>To determine the effectiveness of our marketing and promotional campaigns.</strong>&nbsp;We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</span></li>
                                                                                </ul>
                                                                                <div>
                                                                                    <div></div>
                                                                                    <ul>
                                                                                        <li><span data-custom-class="body_text"><strong>To save or protect an individual’s vital interest.</strong>&nbsp;We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</span></li>
                                                                                    </ul>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div id="legalbases"><strong><span data-custom-class="heading_1">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></strong></div>
                                                                                    <div></div>
                                                                                    <div><em><span data-custom-class="body_text"><strong>In Short:&nbsp;</strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e.&nbsp;legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or&nbsp;fulfil&nbsp;our contractual obligations, to protect your rights, or to&nbsp;fulfil&nbsp;our legitimate business interests.</span></em></div>
                                                                                    <div></div>
                                                                                    <div><span data-custom-class="body_text">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span></div>
                                                                                    <ul>
                                                                                        <li><span data-custom-class="body_text"><strong>Consent.&nbsp;</strong>We may process your information if you have given us permission (i.e.&nbsp;consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click&nbsp;</span><a href="#withdrawconsent" data-custom-class="link"><span data-custom-class="body_text">here</span></a><span data-custom-class="body_text">&nbsp;to learn more.</span></li>
                                                                                    </ul>
                                                                                    <div>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text"><strong>Legitimate Interests.</strong>&nbsp;We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Send users information about special offers and discounts on our products and services</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Develop and display&nbsp;personalised&nbsp;and relevant advertising content for our users</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Analyse&nbsp;how our Services are used so we can improve them to engage and retain users</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Support our marketing activities</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Diagnose problems and/or prevent fraudulent activities</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Understand how our users use our products and services so we can improve user experience</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text"><strong>Legal Obligations.</strong>&nbsp;We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</span><br />
                                                                                            </li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text"><strong>Vital Interests.</strong>&nbsp;We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <div></div>
                                                                                        <div id="whoshare"><span id="control"><strong><span data-custom-class="heading_1">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></span></div>
                                                                                        <div></div>
                                                                                        <div><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may share information in specific situations described in this section and/or with the following&nbsp;categories of&nbsp;third parties.</em></span></div>
                                                                                        <div></div>
                                                                                        <div></div>
                                                                                        <div><span data-custom-class="body_text"><strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong>&nbsp;We may share your data with third-party vendors, service providers, contractors, or agents (&nbsp;‘<strong>third parties</strong>‘&nbsp;) who perform services for us or on our behalf and require access to such information to do that work.&nbsp;We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any&nbsp;organisation&nbsp;apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.&nbsp;The&nbsp;categories of&nbsp;third parties we may share personal information with are as follows:</span></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Data Analytics Services</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Data Storage Service Providers</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Communication &amp; Collaboration Tools</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Finance &amp; Accounting Tools</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Order&nbsp;Fulfilment&nbsp;Service Providers</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Payment Processors</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Performance Monitoring Tools</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Product Engineering &amp; Design Tools</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Retargeting Platforms</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Sales &amp; Marketing Tools</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Social Networks</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Testing Tools</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Website Hosting Service Providers</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">User Account Registration &amp; Authentication Services</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Ad Networks</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Affiliate Marketing Programs</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text">Cloud Computing Services</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <div></div>
                                                                                        <div><span data-custom-class="body_text">We&nbsp;also&nbsp;may need to share your personal information in the following situations:</span></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text"><strong>Business Transfers.</strong>&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></li>
                                                                                        </ul>
                                                                                        <div></div>
                                                                                        <ul>
                                                                                            <li><span data-custom-class="body_text"><strong>When we use Google Maps Platform APIs.</strong>&nbsp;We may share your information with certain Google Maps Platform APIs (e.g.&nbsp;Google Maps API, Places API). To find out more about Google’s Privacy Policy, please refer to this&nbsp;</span><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" data-custom-class="link"><span data-custom-class="body_text">link</span></a><span data-custom-class="body_text">.&nbsp;We obtain and store on your device (&nbsp;‘cache’&nbsp;) your location&nbsp;. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</span></li>
                                                                                        </ul>
                                                                                        <div>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <div>
                                                                                                        <div></div>
                                                                                                        <div></div>
                                                                                                        <div id="cookies"><span id="control"><strong><span data-custom-class="heading_1">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice&nbsp;.</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="sociallogins"><span id="control"><strong><span data-custom-class="heading_1">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</em></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="inforetain"><span id="control"><strong><span data-custom-class="heading_1">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We keep your information for as long as necessary to&nbsp;fulfil&nbsp;the purposes outlined in this privacy notice unless otherwise required by law.</em></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).&nbsp;No purpose in this notice will require us keeping your personal information for longer than&nbsp;the period of time in which users have an account with us&nbsp;.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or&nbsp;anonymise&nbsp;such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="infosafe"><span id="control"><strong><span data-custom-class="heading_1">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We aim to protect your personal information through a system of&nbsp;organisational&nbsp;and technical security measures.</em></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">We have implemented appropriate and reasonable technical and&nbsp;organisational&nbsp;security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other&nbsp;unauthorised&nbsp;third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="infominors"><span id="control"><strong><span data-custom-class="heading_1">9. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market to&nbsp;children under 18 years of age&nbsp;.</em></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at&nbsp;info@reviseukmla.com&nbsp;.</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="privacyrights"><span id="control"><strong><span data-custom-class="heading_1">10. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;In some regions, such as&nbsp;the European Economic Area (EEA) and United Kingdom (UK)&nbsp;, you have rights that allow you greater access to and control over your personal information.&nbsp;You may review, change, or terminate your account at any time.</em></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">In some regions (like&nbsp;the EEA and UK&nbsp;), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section&nbsp;‘&nbsp;</span><a href="#contact" data-custom-class="link"><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a><span data-custom-class="body_text">&nbsp;‘&nbsp;below.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">We will consider and act upon any request in accordance with applicable data protection laws.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:&nbsp;<a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noopener noreferrer" data-custom-class="link">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">If you are located in Switzerland, the contact details for the data protection authorities are available here:&nbsp;<a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noopener noreferrer" data-custom-class="link">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</span></div>
                                                                                                        <div></div>
                                                                                                        <div id="withdrawconsent"><span data-custom-class="body_text"><strong><u>Withdrawing your consent:</u></strong>&nbsp;If we are relying on your consent to process your personal information,&nbsp;you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section&nbsp;‘&nbsp;</span><a href="#contact" data-custom-class="link"><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a><span data-custom-class="body_text">&nbsp;‘&nbsp;below&nbsp;.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,&nbsp;will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><u>Opting out of marketing and promotional communications:</u></strong><strong><u>&nbsp;</u></strong>You can unsubscribe from our marketing and promotional communications at any time by&nbsp;clicking on the unsubscribe link in the emails that we send,&nbsp;replying&nbsp;‘STOP’ or ‘UNSUBSCRIBE’&nbsp;to the SMS messages that we send,&nbsp;or by contacting us using the details provided in the section&nbsp;‘&nbsp;</span><a href="#contact" data-custom-class="link"><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a><span data-custom-class="body_text">&nbsp;‘&nbsp;below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="heading_2"><strong>Account Information</strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">If you would at any time like to review or change the information in your account or terminate your account, you can:</span></div>
                                                                                                        <ul>
                                                                                                            <li><span data-custom-class="body_text">Log in to your account settings and update your user account.</span></li>
                                                                                                        </ul>
                                                                                                        <div></div>
                                                                                                        <ul>
                                                                                                            <li><span data-custom-class="body_text">Contact us using the contact information provided.</span></li>
                                                                                                        </ul>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><u>Cookies and similar technologies:</u></strong>&nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit&nbsp;<a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer" data-custom-class="link">http://www.aboutads.info/choices/</a>.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">If you have questions or comments about your privacy rights, you may email us at&nbsp;info@reviseukmla.com&nbsp;.</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="DNT"><span id="control"><strong><span data-custom-class="heading_1">11. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&nbsp;‘DNT’&nbsp;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for&nbsp;recognising&nbsp;and implementing DNT signals has been&nbsp;finalised&nbsp;. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="caresidents"><span id="control"><strong><span data-custom-class="heading_1">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the&nbsp;‘Shine The Light’&nbsp;law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g.&nbsp;backups, etc.).</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="policyupdates"><span id="control"><strong><span data-custom-class="heading_1">13. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text"><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated&nbsp;‘Revised’&nbsp;date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="contact"><span id="control"><strong><span data-custom-class="heading_1">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">If you have questions or comments about this notice, you may&nbsp;email us at&nbsp;info@reviseukmla.com&nbsp;or by post to:</span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">__________</span></div>
                                                                                                        <div><span data-custom-class="body_text">__________</span></div>
                                                                                                        <div><span data-custom-class="body_text">__________</span></div>
                                                                                                        <div></div>
                                                                                                        <p>&nbsp;</p>
                                                                                                        <div id="request"><span id="control"><strong><span data-custom-class="heading_1">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></span></div>
                                                                                                        <div></div>
                                                                                                        <div><span data-custom-class="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it.&nbsp;To request to review, update, or delete your personal information, please&nbsp;submit a request form by clicking&nbsp;</span><span data-custom-class="body_text"><a href="https://app.termly.io/notify/2c421cca-3d27-4e80-b0af-f49aa42c3e03" target="_blank" rel="noopener noreferrer" data-custom-class="link">here</a></span>&nbsp;<span data-custom-class="body_text">.</span></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy