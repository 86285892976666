import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { UserContext } from '../../Contexts/UserProvider';
import Cookies from 'js-cookie';
import { Table, Alert } from 'react-bootstrap';
import './Stats.css';
import CategoryBarChart from '../CategoryBarChart/CategoryBarChart';

const Stats = () => {
  const [stats, setStats] = useState([]);
  const [error, setError] = useState('');
  const [loadingStats, setLoadingStats] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const { user, loading } = useContext(UserContext);

  useEffect(() => {
    if (!loading) {
      fetchStats();
    }
  }, [loading]);

  const fetchStats = async () => {
    const token = Cookies.get('token');
    if (!token) {
      setError('You need to log in to see your stats.');
      return;
    }

    try {
      const response = await axios.get('https://api.reviseukmla.com/api/v1/users/performance', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setStats(calculateCategoryStats(response.data.performance));
        setLoadingStats(false);
      } else {
        setError('Failed to fetch stats');
      }
    } catch (err) {
      setError('Failed to fetch stats');
    }
    // console.log(stats);
  };
  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));

  const calculateCategoryStats = (categories) => {
    if (!categories) return [];

    let overallCorrect = 0;
    let overallIncorrect = 0;
    let overallUnanswered = 0;
    let overallTotal = 0;

    const categoryStats = categories.map(category => {
      let totalCorrect = 0;
      let totalIncorrect = 0;
      let totalUnanswered = 0;
      let totalQuestions = 0;

      category.topics.forEach(topic => {
        totalCorrect += topic.correctAnswers;
        totalIncorrect += topic.incorrectAnswers;
        totalUnanswered += topic.unanswered;
        totalQuestions += topic.totalQuestions;
      })

      overallCorrect += totalCorrect;
      overallIncorrect += totalIncorrect;
      overallUnanswered += totalUnanswered;
      overallTotal += totalQuestions;

      const score = totalQuestions !== 0 ? (totalCorrect / totalQuestions) * 100 : 0;
      return {
        categoryName: category.categoryName,
        abbrv: category.abbrv,
        correct: totalCorrect,
        incorrect: totalIncorrect,
        unanswered: totalUnanswered,
        total: totalQuestions,
        score: score
      };
    }).sort((a, b) => b.score - a.score);

    // Calculate overall percentage
    const overallScore = overallTotal !== 0 ? (overallCorrect / overallTotal) * 100 : 0;

    // Add overall row to the end of categoryStats
    categoryStats.push({
      categoryName: 'Overall',
      abbrv: 'Overall',
      correct: overallCorrect,
      incorrect: overallIncorrect,
      unanswered: overallUnanswered,
      total: overallTotal,
      score: overallScore
    });
    // console.log(categoryStats);
    return categoryStats.filter(category => category.total !== 0);
  };

  const filterData = (data) => {
    const filteredData = data.filter(item => item.value !== 0)
    return filteredData;
  }

  return (
    <div className='container login-main stats-main'>
      <h2>Overall Performance</h2>
      {loadingStats && <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}
      {error && <Alert variant="danger">{error}</Alert>}
      {stats.length > 0 && <CategoryBarChart setSelectedCategory={setSelectedCategory} stats={stats.slice(0, stats.length - 1)} />}
      <div className='pie-chart'>
        {(stats.length) ?
          <>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              justifyContent="space-between"
              sx={{ width: '100%' }}
            >
              <div style={{ position: 'relative', width: '400px', height: '300px' }} className='d-flex justify-content-center'>
                <PieChart
                  colors={filterData([{
                    id: 0,
                    value: stats[selectedCategory].correct,
                    label: 'Correct',
                    color: 'green'
                  }, {
                    id: 1,
                    value: stats[selectedCategory].incorrect,
                    label: 'Incorrect',
                    color: 'red'
                  }, {
                    id: 2,
                    value: stats[selectedCategory].unanswered,
                    label: 'Unanswered',
                    color: 'blue'
                  }]).map(item => item.color)} // Use palette
                  series={[
                    {
                      data: filterData([{
                        id: 0,
                        value: stats[selectedCategory].correct,
                        label: 'Correct',
                        color: 'green'
                      }, {
                        id: 1,
                        value: stats[selectedCategory].incorrect,
                        label: 'Incorrect',
                        color: 'red'
                      }, {
                        id: 2,
                        value: stats[selectedCategory].unanswered,
                        label: 'Unanswered',
                        color: 'blue'
                      }]),
                      innerRadius: 70,
                      paddingAngle: 1,
                      highlightScope: {
                        faded: 'global'
                      }
                    }
                  ]}
                  slotProps={{
                    legend: { hidden: true }
                  }}
                  width={400}
                  height={300}
                >
                </PieChart>
                <Typography
                  component="p"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    maxWidth: "130px",
                    left: '39%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '1.5rem',
                    color: '#333',
                    fontWeight: 'bold',
                    textAlign: "center",
                    fontFamily: "\"Montserrat\", sans-serif",
                    fontWeight: "normal"
                  }}
                >
                  {stats[selectedCategory].abbrv}
                </Typography>
              </div>
              <Typography
                component="div"
                sx={{ maxWidth: { xs: '100%', md: '60%' }, overflow: 'auto' }}
              >
                <h5>{stats[selectedCategory].categoryName}</h5>
                <table className='stats-table mt-0 MsoNormalTable'>
                  <thead>
                    <tr>
                      <td>Correct</td>
                      <td>Incorrect</td>
                      <td>Unanswered</td>
                      <td>Total</td>
                      <td>Score</td>
                      {/* <td>View</td> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><strong>{stats[selectedCategory].correct}</strong></td>
                      <td><strong>{stats[selectedCategory].incorrect}</strong></td>
                      <td><strong>{stats[selectedCategory].unanswered}</strong></td>
                      <td><strong>{stats[selectedCategory].total}</strong></td>
                      <td><strong>{stats[selectedCategory].score.toFixed(2)}%</strong></td>
                    </tr>
                  </tbody>
                </table>
              </Typography>
            </Stack>
          </> : <div></div>}
      </div>
      {/*stats.length ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Category</th>
              <th>Correct Answers</th>
              <th>Incorrect Answers</th>
              <th>Unanswered</th>
              <th>Total</th>
              <th>Score (%)</th>
              {/* <th>View</th>}
            </tr>
          </thead>
          <tbody>
            {stats.map((category, index) => (
              <tr key={index} onMouseEnter={() => setSelectedCategory(index)}>
                <td>{category.categoryName}</td>
                <td>{category.correct}</td>
                <td>{category.incorrect}</td>
                <td>{category.unanswered}</td>
                <td>{category.total}</td>
                <td>{category.score.toFixed(2)} %</td>
                {/* <td><button className='view' onClick={() => setSelectedCategory(index)}>View</button></td> }
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !error && <div></div>
      )*/}

    </div >
  );
};

export default Stats;
