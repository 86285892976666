import React, { useState } from 'react';
import './CategoryBarChart.css';
import { Box } from '@mui/material';

const CategoryBarChart = ({ setSelectedCategory, stats }) => {
    const [xMax, setXMax] = useState(800);
    const [xMin, setXMin] = useState(0);
    const [targetValue, setTargetValue] = useState(xMax * 0.75);

    const handleMouseEnter = (event, stat) => {
        const tooltip = document.getElementById('tooltip');
        tooltip.style.display = 'block';
        tooltip.style.left = `${event.pageX + 25}px`;
        tooltip.style.top = `${event.pageY + 25}px`;
        // console.log(event)
        tooltip.innerHTML = `
            <div><strong>${stat.categoryName}</strong></div>
            <hr/>
            <div>Correct: ${stat.correct}</div>
            <div>Incorrect: ${stat.incorrect}</div>
            <div>Unanswered: ${stat.unanswered}</div>
            <hr/>
            <div>Pass: ${((stat.correct / stat.total) * 100).toFixed()}%</div>
        `;
    };

    const handleMouseLeave = () => {
        const tooltip = document.getElementById('tooltip');
        tooltip.style.display = 'none';
    };
    const handleWheel = (event) => {
        const zoomFactor = 25; // How much to zoom in/out on each scroll
        const deltaY = event.deltaY;

        if (deltaY < 0) { // Scrolling up (zoom in)
            setXMax(prev => Math.max(prev - zoomFactor, xMin + zoomFactor));
        } else { // Scrolling down (zoom out)
            setXMax(prev => prev + zoomFactor);
        }
    };

    const changeScroll = () => {
        let style = document.body.style.overflow;
        document.body.style.overflow = (style === 'hidden') ? 'auto' : 'hidden';
    };

    return (
        <>
            <div className="chart-container" onWheel={handleWheel} onMouseEnter={changeScroll} onMouseLeave={(e) => { changeScroll(); handleMouseLeave() }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: "0",
                        left: `calc(${(targetValue / (xMax - xMin)) * 100}%)`,
                        height: '100%',
                        width: '0',
                        borderLeft: "0",
                        borderRight: '3px dashed #4ea72f',
                        borderStyle: 'dashed',
                        backgroundColor: 'transparent',
                        zIndex: 2,
                    }}
                ></Box>
                <p
                    style={{
                        position: 'absolute',
                        top: "-50px",
                        left: `calc(${(targetValue / (xMax - xMin)) * 100}%)`,
                        transform: "translateX(-50%)",
                        textAlign: "center",
                        width: "200px",
                        backgroundColor: "#4ea72f",
                        border: "1px solid #333",
                        borderRadius: "1rem",
                        color: "white",
                        fontSize: "16px", // Adjust the font size as needed
                        padding: " 0 .25rem",
                        fontSize: "16px", // Adjust the font size as needed
                        fontWeight: "bold", // Makes the text bolder
                        textShadow: "1px 1px 2px rgba(0,0,0,0.5)" // Adds shadow for better visibility
                    }}
                >Target to achieve passable score</p>
                <svg width="936" height={stats.length * 25}>
                    {stats.map((stat, index) => {
                        const y = index * 24;
                        const correctWidth = (stat.correct / xMax) * 1000;
                        const incorrectWidth = (stat.incorrect / xMax) * 1000;
                        const unansweredWidth = (stat.unanswered / xMax) * 1000;

                        return (
                            <g key={index} transform={`translate(0, ${y})`} onMouseEnter={() => setSelectedCategory(index)}>
                                <rect x="0" y="0" width={correctWidth} height="20" fill="green"
                                    onMouseMove={(e) => handleMouseEnter(e, stat)} />
                                <rect x={correctWidth} y="0" width={incorrectWidth} height="20" fill="red"
                                    onMouseMove={(e) => handleMouseEnter(e, stat)} />
                                <rect x={correctWidth + incorrectWidth} y="0" width={unansweredWidth} height="20" fill="gray"
                                    onMouseMove={(e) => handleMouseEnter(e, stat)} />
                                <rect x={correctWidth + incorrectWidth + unansweredWidth} y="0" width="100%" height="20" fill="#DDDDDD"
                                    onMouseMove={(e) => handleMouseEnter(e, stat)} />
                                <text x="99%" y="15" textAnchor='end' cursor="pointer">{stat.categoryName}</text>
                            </g>
                        );
                    })}
                </svg>
            </div>
            <div id="tooltip" className="tooltip-barchart"></div>
        </>
    );
};

export default CategoryBarChart;
