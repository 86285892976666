import React from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css'

const ForgotPassword = (props) => {
    const [err, setErr] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",

    });
    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }
    const forgotPassword = async (e) => {
        e.preventDefault();
        setMessage("");
        setErr("");
        setLoading(true);

        const { email } = user;

        if (email === "") {
            setErr("Please enter your email");
            return;
        }

        try {
            const response = await axios.post('https://api.reviseukmla.com/api/v1/users/forgotPassword', {
                email
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

            if (response.status === 200) {
                setMessage("Password reset email sent.");
            }

        } catch (error) {
            setErr(error.response.data.message); // You can customize this error message.
            // console.log(error)
        }
        setLoading(false);
    }
    return (
        <div>
            <Container className="forgotPassword-main">
                <Row className="justify-content-md-center">
                    <Col md={{ span: 6 }}>
                        <h2>Forgot Password</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md={{ span: 6 }}>
                        <Form>
                            <Form.Group controlId="formBasicEmail" className='mb-3'>

                                <Form.Control name='email' value={user.email} className="customform no-outline" onChange={handleChange} type="email" placeholder="Email" />
                                <div className="text-left mt-2">
                                    Remember your Password? <a href="/login" className="remember-password">Login</a>
                                </div>

                                <div className='text-center pt-3' >
                                    <Button className='custombutton mb-3' type="submit" onClick={forgotPassword}>
                                        Reset Password
                                    </Button>
                                    {err !== "" ? (<Alert key='danger' variant='danger'>{err}</Alert>) : null}
                                    {message !== "" ? (<Alert key='success' variant='success'>{message}</Alert>) : null}
                                    <p className="mt-2">
                                        <a href="/signup" className="create-account">Create account</a>
                                    </p>
                                </div>

                            </Form.Group>
                        </Form>
                        {loading && <div className="spinner-border text-primary spinner" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </Col>
                </Row>
            </Container >
        </div>
    );
}

export default ForgotPassword;