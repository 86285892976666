import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { UserContext } from '../../Contexts/UserProvider';
import StripeCheckout from 'react-stripe-checkout';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import Cookies from 'js-cookie';
import './Subscriptions.css';
import 'animate.css';
import CheckoutForm from '../CheckoutForm/CheckoutForm';

const stripePromise = loadStripe('pk_live_51HqH5iHzuQ2rCeWElc5rODbceMKvKwb7OCN4vL547PJ2ZYcXMOADJ2N7XOn0OlzllhM6lVq7mWHLzQdbcLdbtbCf00I1NGJ8oX');

function Subscriptions(props) {
    const { user, loading, refreshUserData } = useContext(UserContext);
    const [message, setMessage] = useState('');
    const [err, setErr] = useState('');
    const paymentRef = useRef();
    const categoriesRef = useRef();
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingQuiz, setLoadingQuiz] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(props.selectedSubscription);
    const [showCheckout, setShowCheckout] = useState(props.showCheckout);
    const [quizOptions, setQuizOptions] = useState({
        quizOption: "50",
        timedTestOption: "90",
        numQuestions: "50",
        categoryId: [],
        type: "unansweredOnly",
    });
    // const handleBuyClick = (amount, subscriptionType) => {
    //     setSelectedSubscription({ amount, subscriptionType });
    //     setShowCheckout(true);
    //     if (paymentRef.current) {
    //         paymentRef.current.scrollIntoView();
    //     }
    // };
    const navigate = useNavigate();
    const fetchCategories = async () => {
        const token = Cookies.get("token"); // Get token from cookie
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const categoriesResponse = await axios.get("https://api.reviseukmla.com/api/v1/category/getCategories", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            if (categoriesResponse.status === 200) {
                const fetchedCategories = categoriesResponse.data.data.categories;
                setCategories(fetchedCategories);
                setLoadingCategories(false);
            }
            // console.log(categories);
        } catch (err) {
            // console.log(err);
            setErr(err.message);
        }
    };
    useEffect(() => {
        if (user && (user.subscription === "targetedApproach" || user.subscription === "thoroughPractice")) {
            if (user.subscriptionExpires < new Date()) {
                setErr("Your subscription has expired. Please renew to continue.");
                navigate("/");
            } else {
                fetchCategories(user);
            }
        }
        if (quizOptions.quizOption === "category" && categoriesRef.current) {
            categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (!props.selectedSubscription && !user) {
            // If user is not logged in and has no subscription, redirect to Home page
            navigate("/");
        }

    }, []);


    const handleSubmit = async (e) => {
        setLoadingQuiz(true);
        setErr("");
        e.preventDefault();
        const { quizOption, timedTestOption, numQuestions, categoryId, type } = quizOptions;
        // Initialize request data
        let requestData = {
            categories: [],
            numQuestions: 10,
            type: type
        };

        // Validate quiz options and prepare request data
        if (quizOption === "timedTest") {
            if (timedTestOption === "") {
                setErr("Please select a time duration for the timed test");
                return;
            }
            requestData.numQuestions = parseInt(timedTestOption);
        } else if (quizOption === "category") {
            if (categoryId.length === 0) {
                setErr("Please select at least one category");
                return;
            }
            requestData.categories = categoryId;
            requestData.numQuestions = 99999;
        } else {
            requestData.numQuestions = parseInt(quizOption);
        }

        // Add categories if not a category specific quiz
        if (quizOption !== "category") {
            requestData.categories = categories;
        }


        const token = Cookies.get("token"); // Get token from cookie
        if (!token) {
            navigate("/login");
            return;
        }

        // const requestData = {
        //     category: selectedCategory,
        //     numQuestions: numQuestions,
        // };
        // console.log(requestData);
        try {
            const response = await axios.post(
                "https://api.reviseukmla.com/api/v1/quiz/startQuiz",
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setLoadingQuiz(false);

            if (response.status === 200) {
                localStorage.setItem("submittedAnswers", JSON.stringify(response.data.data.questions.map((question) => 0)));
                localStorage.setItem("selectedOptions", JSON.stringify(response.data.data.questions.map((question) => null)));
                localStorage.setItem("correctAnswers", JSON.stringify(response.data.data.questions.map((question) => null)));
                localStorage.setItem("topics", JSON.stringify(response.data.data.questions.map((question) => null)));
                localStorage.setItem("categoryResults", JSON.stringify({}));
                localStorage.setItem("countdownDuration", 0);
                if (quizOption === "timedTest") {
                    localStorage.setItem("countdownDuration", parseInt(timedTestOption) * 60);
                    localStorage.setItem('timerStart', Date.now().toString());
                }
                // Store questions in local storage
                localStorage.setItem("questions", JSON.stringify(response.data.data.questions));
                // Navigate to quiz page
                // console.log(response.data.data.questions);
                navigate("/quiz/#quiz");
            } else {
                setErr("Failed to start quiz");
            }
        } catch (err) {
            // console.log(err);
            setErr(err.message);
        }
    };
    const handleToken = async (token, subscription, amount) => {
        setMessage("");
        setErr('');

        const authToken = Cookies.get('token');
        try {
            const response = await axios.post('https://api.reviseukmla.com/api/v1/subscription/buySubscription', { token, subscription, amount }, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                }
            });
            // console.log(response.data.status);
            if (response.data.status === "success") {
                setMessage('Payment Successful');
                refreshUserData();
                // navigate('/startQuiz');
            } else {
                setErr('Payment Failed');
            }
        } catch (error) {
            setErr('Payment Failed');
        }
    };
    const handleCategoryChange = (e) => {
        const categoryId = e.target.value;
        let updatedSelectedCategories;
        if (quizOptions.categoryId.includes(categoryId)) {
            // Deselect the category
            updatedSelectedCategories = quizOptions.categoryId.filter((id) => id !== categoryId);
        } else {
            if (quizOptions.categoryId.length >= 3) {
                setErr("You can select up to 3 categories only.");
                return;
            }
            // Select the category
            updatedSelectedCategories = [...quizOptions.categoryId, categoryId];
        }

        setErr("");  // Clear any previous error message
        // Assuming you want to update quizOptions as well
        setQuizOptions({
            ...quizOptions,
            categoryId: updatedSelectedCategories,
        });
    };

    const handleQuizOptionsChange = (e) => {
        const option = e.target.value;
        setQuizOptions((prevOptions) => {
            return {
                ...prevOptions,
                [e.target.name]: option
            }
        });
        if (categoriesRef.current) {
            categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        // console.log(quizOptions);
    };

    return (
        <Container className="mt-5 subscriptions-main">
            <h1 className='headline'>Start studying</h1>
            {(user) && (user.subscription === "targetedApproach" || user.subscription === "thoroughPractice") ? (
                <div style={{ marginBottom: '50px' }}>
                    <Row className="justify-content-center">
                        {quizOptions.quizOption === "category" && <Col md={3} />}
                        <Col md={quizOptions.quizOption === "category" ? 6 : 12} className='animate__animated animate__fadeInRight'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="quizOptions">
                                    <Form.Label><strong>Select one from below:</strong></Form.Label>
                                    <Form.Check
                                        type="radio"
                                        className='inp-cbx'
                                        label="Set of 50 questions"
                                        name="quizOption"
                                        value="50"
                                        onChange={handleQuizOptionsChange}
                                        checked={quizOptions.quizOption === "50"}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Set of 100 questions"
                                        name="quizOption"
                                        value="100"
                                        onClick={handleQuizOptionsChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Area of clinical specialty"
                                        name="quizOption"
                                        value="category"
                                        onClick={handleQuizOptionsChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Timed test"
                                        name="quizOption"
                                        value="timedTest"
                                        onClick={handleQuizOptionsChange}
                                    />
                                </Form.Group>
                                {quizOptions.quizOption === 'timedTest' && (
                                    <Form.Group controlId="timedTestOptions" className='animate__animated animate__fadeInDown animate__faster mt-3'>
                                        <Form.Label><strong>Select one:</strong></Form.Label>
                                        <Form.Check
                                            type="radio"
                                            label="90 questions in 90 minutes"
                                            name="timedTestOption"
                                            onChange={handleQuizOptionsChange}
                                            value="90"
                                            checked={quizOptions.timedTestOption === "90"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="180 questions in 3 hours"
                                            name="timedTestOption"
                                            value="180"
                                            onChange={handleQuizOptionsChange}
                                            checked={quizOptions.timedTestOption === "180"}
                                        />
                                    </Form.Group>
                                )}
                                {quizOptions.quizOption !== "" && (
                                    <Form.Group controlId="type" className='mt-3'>
                                        <Form.Label><strong>Choose from one of the following options:</strong></Form.Label>
                                        <Form.Check
                                            type="radio"
                                            label="Show new questions only"
                                            name="type"
                                            value="unansweredOnly"
                                            onChange={handleQuizOptionsChange}
                                            checked={quizOptions.type === "unansweredOnly"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Show all, including new and previous incorrect questions"
                                            name="type"
                                            onChange={handleQuizOptionsChange}
                                            value="unansweredAndIncorrect"
                                            checked={quizOptions.type === "unansweredAndIncorrect"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Show incorrect questions only"
                                            name="type"
                                            onChange={handleQuizOptionsChange}
                                            value="incorrectOnly"
                                            checked={quizOptions.type === "incorrectOnly"}
                                        />
                                    </Form.Group>
                                )}
                                {quizOptions.quizOption !== "category" && <Button className='mt-3' variant="primary" type="submit">
                                    Start Studying
                                </Button>}
                                {loadingQuiz && (
                                    <div className="spinner-border text-primary" role="status" style={{ marginLeft: "47%", marginTop: "20px" }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                            </Form>
                        </Col>
                        {quizOptions.quizOption === "category" && <Col md={3} />}
                    </Row>
                    {quizOptions.quizOption === "category" ? <Row ref={categoriesRef} className='mt-4 start-quiz-container animate__animated animate__fadeInDown categories'>
                        <h2>Select Category:</h2>
                        {loadingCategories === true ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <>
                                <Col md={6}>
                                    {categories.slice(0, Math.ceil(categories.length / 2)).map((category) => (
                                        <div className="checkbox-wrapper-46" key={category._id}>
                                            <input
                                                type="checkbox"
                                                id={`cbx-${category._id}`}
                                                name="categoryId"
                                                className="inp-cbx"
                                                value={category._id}
                                                onChange={handleCategoryChange}
                                                checked={quizOptions.categoryId.includes(category._id)}
                                            />
                                            <label htmlFor={`cbx-${category._id}`} className="cbx">
                                                <span>
                                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                    </svg>
                                                </span>
                                                <span>{category.name}</span>
                                            </label>
                                        </div>
                                    ))}
                                </Col>
                                <Col md={6}>
                                    {categories.slice(Math.ceil(categories.length / 2)).map((category) => (
                                        <div className="checkbox-wrapper-46" key={category._id}>
                                            <input
                                                type="checkbox"
                                                id={`cbx-${category._id}`}
                                                name="categoryId"
                                                className="inp-cbx"
                                                value={category._id}
                                                onChange={handleCategoryChange}
                                                checked={quizOptions.categoryId.includes(category._id)}
                                            />
                                            <label htmlFor={`cbx-${category._id}`} className="cbx">
                                                <span>
                                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                    </svg>
                                                </span>
                                                <span>{category.name}</span>
                                            </label>
                                        </div>
                                    ))}
                                </Col>
                            </>
                        )}
                        <button className="btn btn-primary custombutton mt-3" type="submit" onClick={handleSubmit}>
                            Start Studying
                        </button>
                        {err !== "" ? (
                            <Alert key="danger" variant="danger">
                                {err}
                            </Alert>
                        ) : null}
                        {message !== "" ? (
                            <Alert key="success" variant="success">
                                {message}
                            </Alert>
                        ) : null}
                        {/* <Col md={5} className="quiz-settings">
                                    <form onSubmit={handleSubmit}>
                                        <div className='mb-3'>
                                            <label>Number of Questions (1-200):</label>
                                            <input
                                                type="number"
                                                name="numQuestions"
                                                value={quizOptions.numQuestions}
                                                onChange={handleQuizOptionsChange}
                                                min="1"
                                                max="200"
                                            />
                                        </div>
                                    </form>
                                </Col> */}
                    </Row> : <Row ref={categoriesRef}></Row>}
                </div>
            ) : (
                <div className='subscriptions-main'>


                    {showCheckout ? (
                        <Row id='payment' ref={paymentRef}>
                            <Col md={1} />
                            <Col md={10}>
                                <p className='mb-4'>By choosing a subscription plan, you agree to our terms and conditions. Please note that your subscription will automatically expire at the end of the 6-month period.</p>
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm
                                        handleToken={handleToken}
                                        user={user}
                                        refreshUserData={refreshUserData}
                                        amount={selectedSubscription.amount}
                                        subscriptionType={selectedSubscription.subscriptionType}
                                    />
                                </Elements>
                            </Col>
                            <Col md={1} />
                        </Row>

                    ) : <Row ref={paymentRef}></Row>}
                    {err !== "" ? (
                        <Alert key="danger" variant="danger">
                            {err}
                        </Alert>
                    ) : null}
                    {message !== "" ? (
                        <Alert key="success" variant="success">
                            {message}
                        </Alert>
                    ) : null}
                </div>
            )
            }
        </Container >
    );
}

export default Subscriptions;
