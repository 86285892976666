import React, { useEffect, useState, useRef } from 'react'
import _1 from "./Images/1.jpg"
import _2 from "./Images/2.jpg"
import _3 from "./Images/3.jpg"
import _4 from "./Images/4.jpg"
import _5 from "./Images/5.jpg"
import _6 from "./Images/6.jpg"
import _7 from "./Images/7.jpg"
import _8 from "./Images/8.jpg"
import _9 from "./Images/9.jpg"
import _10 from "./Images/10.jpg"
import _11 from "./Images/11.jpg"
import _12 from "./Images/12.jpg"
import _13 from "./Images/13.jpg"
import './AboutUs.css'
import { Col, Row } from 'react-bootstrap'

function AboutUs() {
    const images = [_1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13];
    const [currentImage, setCurrentImage] = useState(0);
    const imgRef = useRef(null); // Reference to the img element

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval); // Cleanup interval
    }, [images.length]);

    useEffect(() => {
        // This effect will re-apply the animation each time the image changes
        const imgElement = imgRef.current;
        if (imgElement) {
            imgElement.classList.remove('animated'); // Remove the animation class
            void imgElement.offsetWidth; // Trigger a DOM reflow, allowing the animation to restart
            imgElement.classList.add('animated'); // Re-add the animation class
        }
    }, [currentImage]); // Runs whenever the currentImage state changes

    return (
        <div className='container text-justify mt-4'>
            <Row>
                <Col md={6} style={{ padding: "50px 50px 0 50px" }}>
                    <img
                        ref={imgRef}
                        className='about-us-img animated'
                        src={images[currentImage]}
                        alt={`Image ${currentImage + 1}`}
                        width="100%"
                    />
                </Col>
                <Col md={6} className='pl-0'>
                    <h1 className='terms-and-conditions'>Who We Are?</h1>
                    <p><span
                    >Welcome to ReviseUKMLA, your trusted partner in preparing for the UK(MLA) – 'the old
                        PLAB 1'.</span></p>

                    <p><span
                    >At ReviseUKMLA, we are dedicated to providing high-quality online revision
                        materials specifically for the UKMLA. Our questions are meticulously crafted by
                        practising senior NHS doctors to ensure relevance and accuracy. By focusing
                        exclusively on this exam, we are committed to delivering excellence for
                        international medical graduates and final-year medical students. Our commitment
                        to quality means we avoid filler content and outdated questions, continuously
                        refining our resources based on user feedback and performance data.</span></p>
                </Col>
            </Row>
            <p><span
            >We recognise the financial pressures associated with medical exams and usual
                registration fees as well as travel costs for international students. That's
                why ReviseUKMLA is committed to offering exceptional revision resources at an
                affordable price. Instead of investing in costly advertising, we rely on
                word-of-mouth recommendations from our satisfied users. If you find our
                platform useful, we encourage you to share it with your colleagues.</span></p>

            <p><span
            >While ReviseUKMLA is not a one-size-fits-all solution for passing your exams, we
                believe it can significantly boost your chances of success. We advise speaking
                with peers who have successfully passed their exams and creating a study plan
                that incorporates their recommendations.</span></p>

            <p><span
            >Explore our resources with a free demo and discover how ReviseUKMLA can support your
                exam preparation journey.</span></p>

            <p><span
            >On behalf of the whole team, we wish you all the best with your journey.</span></p>

            <p><span
            >Director,</span></p>

            <p><span
            >ReviseUKMLA</span></p>
        </div>
    )
}

export default AboutUs