import React from 'react'
import './TermsConditions.css'

function TermsConditions() {
    return (
        <div className='container mb-4 text-justify'>
            <h1 className='terms-and-conditions'>Terms and Conditions</h1>
            <section>
                <h2>1. Agreement to Terms</h2>
                <p>By accessing or using the website at <a href="https://www.reviseukmla.com">https://www.reviseukmla.com</a> (the "Site"), you confirm that you have read, understood, and agreed to be bound by these Terms of Use. These terms form a legally binding agreement between you and ReviseUKMLA ("Company", "we", "us", or "our"). If you do not agree with these terms, you must not use the Site.</p>
            </section>

            <section>
                <h2>2. Changes to Terms</h2>
                <p>We may update these terms from time to time to reflect changes in our services or legal requirements. The latest version of these terms will always be available on the Site. By continuing to use the Site after changes are posted, you agree to be bound by the updated terms.</p>
            </section>

            <section>
                <h2>3. Accessing the Site</h2>
                <p>The Site is intended for individuals who are at least 18 years old. If you are under 18, you are not permitted to use the Site. You are responsible for ensuring that your use of the Site complies with local laws and regulations.</p>
            </section>

            <section>
                <h2>4. Intellectual Property Rights</h2>
                <p>The content on the Site, including text, graphics, logos, images, and software, is the property of the Company or is used under license and is protected by intellectual property laws. You may not use any content from the Site for commercial purposes without obtaining a license from us.</p>
            </section>

            <section>
                <h2>5. Use of the Site</h2>
                <p>You are granted a limited license to access the Site for your personal, non-commercial use. This license does not include any resale or commercial use of the Site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of the Site or its contents; or any use of data mining, robots, or similar data gathering and extraction tools.</p>
            </section>

            <section>
                <h2>6. User Contributions</h2>
                <p>The Site may allow you to post content, including comments and other materials. Anything that you post or otherwise make available on the Site is referred to as "User Contributions." You represent and warrant that you own or control all rights in and to the User Contributions and have the right to grant the license granted below to us.</p>
            </section>

            <section>
                <h2>7. Third-Party Links</h2>
                <p>The Site may contain links to other websites operated by third parties. These links are provided for your convenience only. We have no control over these websites and accept no responsibility for them or for any loss or damage that may arise from your use of them.</p>
            </section>

            <section>
                <h2>8. Disclaimers</h2>
                <p>The Site is provided on an "as is" and "as available" basis. We do not guarantee that the Site, or any content on it, will always be available or be uninterrupted. We will not be liable for any loss or damage that may result from the use of the Site.</p>
            </section>

            <section>
                <h2>9. Contact Us</h2>
                <p>If you have any questions about these terms, please contact us at <a href="mailto:support@reviseukmla.com">support@reviseukmla.com</a>.</p>
            </section>
        </div>
    )
}

export default TermsConditions