import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import StripeCheckout from 'react-stripe-checkout';
import { Row, Col, Button, Alert } from 'react-bootstrap'
import './Home.css'
import { UserContext } from "../../Contexts/UserProvider";
import TrialQuiz from "../TrialQuiz/TrialQuiz";

function Home(props) {
  const { user, loading, refreshUserData } = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [err, setErr] = useState('');
  const navigate = useNavigate();

  const handleToken = async (token, subscription, amount) => {
    setMessage("");
    setErr('');
    if (!user) {
      navigate('/login');
      return;
    }

    const authToken = Cookies.get('token');
    try {
      const response = await axios.post('https://api.reviseukmla.com/api/v1/subscription/buySubscription', { token, subscription, amount }, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
      // console.log(response.data.status);
      if (response.data.status === "success") {
        setMessage('Payment Successful');
        refreshUserData();
        navigate('/subscriptions');
      } else {
        setErr('Payment Failed');
      }
    } catch (error) {
      setErr('Payment Failed');
    }
  };
  return (
    <div className='home-main'>
      <div className='container'>
        <Row className="justify-content-center mt-4">
          <Col md={6} className="flex-center">
            <h2 className="white-heading"><strong>Question bank designed strictly following the GMC's MLA content map</strong></h2><br />
            <h3 className="white-heading">Only thing that will surprise you is your result!</h3>
            <h1 className="white-heading d-md-none d-lg-none" style={{ fontSize: "60px" }}>Revise UKMLA</h1>
          </Col>
          <Col md={3} className="mb-2 subscription-card-main">
            <div class="card">
              <div class="title">Targeted Approach</div>
              <div class="content">
                <div class="price">£15</div>
                <div class="description">
                  <p>✔ 6 months access</p>
                  <p>✔ 3500 MCQs</p>
                  <p>✔ Covering all areas of clinical practice</p>
                  <p>✔ Detailed analysis of your weak areas</p>
                  <p>✔ All reading material provided</p>
                </div>
              </div>
              {user ? (
                (user.subscription !== 'targetedApproach' || user.subscriptionExpires < new Date()) ? (
                  (user.subscriptionExpires < new Date() ? (
                    <StripeCheckout
                      stripeKey="pk_live_51HqH5iHzuQ2rCeWElc5rODbceMKvKwb7OCN4vL547PJ2ZYcXMOADJ2N7XOn0OlzllhM6lVq7mWHLzQdbcLdbtbCf00I1NGJ8oX"
                      token={(token) => handleToken(token, "targetedApproach", 1500)}
                      amount={1500}
                      name="Targeted Approach"
                      currency="GBP"
                    >
                      <Button
                        variant="primary"
                        className=''
                        onClick={() => { navigate('/subscriptions'); }}
                      >
                        Buy Now
                      </Button>
                    </StripeCheckout>
                  ) : (<Button
                    variant="primary"
                    className=''
                    onClick={() => { setErr("You already have an active subscription"); }}
                  >
                    Buy Now
                  </Button>)
                  )
                ) : (<Button variant="primary" className='' href='/subscriptions'>Start studying</Button>)
              ) : (<Button variant="primary" className='' onClick={() => { props.handleBuyClick(1500, "targetedApproach"); navigate('/subscriptions') }}>Buy Now</Button>)}
            </div>
          </Col>
          <Col md={3} className="mb-2 subscription-card-main">
            <div class="card">
              <div class="title">Thorough Practice</div>
              <div class="content">
                <div class="price">£25</div>
                <div class="description">
                  <p>✔ 6 months access</p>
                  <p>✔ 6000 MCQs</p>
                  <p>✔ Covering all areas of clinical practice</p>
                  <p>✔ Detailed analysis of your weak areas</p>
                  <p>✔ All reading material provided</p>
                </div>
              </div>
              {user ? (
                (user.subscription !== "thoroughPractice" || user.subscriptionExpires < new Date()) ? (
                  (user.subscriptionExpires < new Date() ? (
                    <StripeCheckout
                      stripeKey="pk_live_51HqH5iHzuQ2rCeWElc5rODbceMKvKwb7OCN4vL547PJ2ZYcXMOADJ2N7XOn0OlzllhM6lVq7mWHLzQdbcLdbtbCf00I1NGJ8oX"
                      token={(token) => handleToken(token, "thoroughPractice", 2500)}
                      amount={2500}
                      name="Thorough Practice"
                      currency="GBP"
                    >
                      <Button
                        variant="primary"
                        className=''
                        onClick={() => { if (user.subscriptionExpires > new Date()) { setErr("You already have an active subscription"); return; } }}
                      >
                        Buy Now
                      </Button>
                    </StripeCheckout>
                  ) : ((<Button
                    variant="primary"
                    className=''
                    onClick={() => { setErr("You already have an active subscription"); }}
                  >
                    Buy Now
                  </Button>)))
                )
                  : (<Button variant="primary" className='' href='/subscriptions'>Start studying</Button>)
              ) : (<Button onClick={() => { props.handleBuyClick(2500, "thoroughPractice"); navigate('/subscriptions') }}>Buy Now</Button>)}
            </div>
          </Col>
          <Col md={6}>
            <h1 className="white-heading d-none d-sm-none d-md-block d-lg-block" style={{ fontSize: "60px", transform: "translateY(-15%)" }}>Revise UKMLA</h1>
          </Col>
          <Col md={6}>
            <TrialQuiz />
            {err !== "" ? (
              <Alert key="danger" variant="danger">
                {err}
              </Alert>
            ) : null}
          </Col>
        </Row>
      </div>
    </div >
  )
}

export default Home