import { UserContext } from "../../Contexts/UserProvider";
import React, { useState, useEffect, useRef, useMemo, useContext, useCallback } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import "./SubCategories.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Alert, Table, Dropdown, Form, Button, Accordion, Card } from "react-bootstrap";
import { ReactTags } from 'react-tag-autocomplete';
import AddTopic from "../AddTopic/AddTopic";

function SubCategories() {
    const [err, setErr] = useState("");
    const [message, setMessage] = useState("");
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [selectedTopicTags, setSelectedTopicTags] = useState([]);
    const [selectedCategoryTags, setSelectedCategoryTags] = useState([]);

    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const [dropdownVisible, setDropdownVisible] = useState({});
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const quillRef = useRef(null);
    useEffect(() => {
        if (user.role !== "admin") {
            navigate(-1);
            return;
        }
        getCategories();
        getSubCategories();
    }, [navigate, user.role]);

    const getCategories = async () => {
        const token = Cookies.get("token");
        const response = await axios.get(
            `https://api.reviseukmla.com/api/v1/category/getAllCategories`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const categories = await response.data.data.categories;
        setCategories(categories);
    };

    const getSubCategories = async () => {
        const token = Cookies.get("token");
        const response = await axios.get(
            `https://api.reviseukmla.com/api/v1/category/getSubCategories`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const sub_categories = await response.data.data.subCategories;
        setSubCategories(sub_categories);
    };

    const updateSubCategory = async (subCategoryId, categoryId, action) => {
        try {
            const token = Cookies.get("token");
            const response = await axios.post(
                `https://api.reviseukmla.com/api/v1/category/updateSubCategory`,
                {
                    id: subCategoryId,
                    categoryId,
                    action,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setMessage(response.data.message);
            getSubCategories(); // Refresh the subcategories
        } catch (error) {
            setErr(error.response ? error.response.data.message : "Server error");
        }
    };

    const toggleDropdown = (subCategoryId, categoryId) => {
        setDropdownVisible((prevState) => ({
            [`${subCategoryId}-${categoryId}`]: !prevState[`${subCategoryId}-${categoryId}`],
        }));
    };

    const handleSelect = (subCategoryId, categoryId, action) => {
        updateSubCategory(subCategoryId, categoryId, action);
    };

    const getTopics = async (subCategory) => {
        try {
            const token = Cookies.get("token");
            const response = await axios.get(
                `https://api.reviseukmla.com/api/v1/category/getTopics/?subCategory=${subCategory}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setTopics(response.data.data.topics);

        } catch (error) {
            setErr("No Topics for the current Sub-Category");
            setMessage("");
            setTopics([]);
            // console.error("Error fetching topics:", error);
            // Handle the error appropriately here
            // For example, you might want to show a notification to the user
        }
    };

    const getQuestions = async (topic) => {
        try {
            const token = Cookies.get("token");
            const response = await axios.get(
                `https://api.reviseukmla.com/api/v1/quiz/getQuestionbyTopic/?topic=${topic}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setSelectedQuestions(response.data.data.questions);

        } catch (error) {
            setErr("No Questions for the current Topic");
            setMessage("");
            setSelectedQuestions([]);
            // console.error("Error fetching topics:", error);
            // Handle the error appropriately here
            // For example, you might want to show a notification to the user
        }
    }


    const dropTopic = async (topic) => {
        try {
            // Confirm before deleting
            const userConfirmed = window.confirm("Are you sure you want to delete this topic?");
            if (!userConfirmed) {
                return; // Stop the function if the user cancels the confirmation dialog
            }

            const token = Cookies.get("token");
            const response = await axios.delete(
                `https://api.reviseukmla.com/api/v1/category/dropTopic/?topic=${topic}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const updatedTopics = topics.filter((t) => t._id !== topic);
            setTopics(updatedTopics);
            setSelectedTopic(null);
            setMessage(response.data.message);
        } catch (error) {
            setErr("Error Deleting topics");
            setMessage("");
            // console.error("Error fetching topics:", error);
            // Handle the error appropriately here
            // For example, you might want to show a notification to the user
        }
    };

    const deactivateQuestion = async (questionId) => {
        try {
            // Confirm before deleting
            const userConfirmed = window.confirm("Are you sure you want to deactivate this question?");
            if (!userConfirmed) {
                return; // Stop the function if the user cancels the confirmation dialog
            }

            // If confirmed, proceed with deletion
            const token = Cookies.get("token");
            const response = await axios.delete(
                `https://api.reviseukmla.com/api/v1/quiz/deactivateQuestion/${questionId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update state or perform any actions needed after successful deletion
            // For instance, removing the question from the local list of questions
            getQuestions(selectedTopic._id);
            setMessage("Question deleted successfully");

        } catch (error) {
            console.error("Error deleting question:", error);
            setErr("Error deleting the question");
            setMessage("");
            // Optionally, you can display more detailed error messages based on the error status code
        }
    };

    const activateQuestion = async (questionId) => {
        try {
            // Confirm before deleting
            const userConfirmed = window.confirm("Are you sure you want to activate this question?");
            if (!userConfirmed) {
                return; // Stop the function if the user cancels the confirmation dialog
            }

            // If confirmed, proceed with deletion
            const token = Cookies.get("token");
            const response = await axios.delete(
                `https://api.reviseukmla.com/api/v1/quiz/activateQuestion/${questionId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Update state or perform any actions needed after successful deletion
            // For instance, removing the question from the local list of questions
            getQuestions(selectedTopic._id);
            setMessage("Question activated successfully");

        } catch (error) {
            // console.error("Error activating question:", error);
            setErr("Error activating the question");
            setMessage("");
            // Optionally, you can display more detailed error messages based on the error status code
        }
    };

    const handleTagAddition = useCallback((newTag) => {
        setSelectedTopicTags([...selectedTopicTags, newTag]);
    }, [selectedTopicTags]);

    const handleTagDelete = (i) => {
        const updatedTagList = selectedTopicTags.filter((_, index) => index !== i);
        setSelectedTopicTags(updatedTagList);
    };
    const handleCategoryTagsAddition = useCallback((newTag) => {
        setSelectedCategoryTags([...selectedCategoryTags, newTag]);
    }, [selectedCategoryTags]);

    const handleCategoryTagsDelete = (i) => {
        const updatedTagList = selectedCategoryTags.filter((_, index) => index !== i);
        setSelectedCategoryTags(updatedTagList);
    };

    function decodedHtml(html) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
    }

    const getSubCategoryNames = (ids) => {
        return ids.map(id => {
            const subCategory = subCategories.find(sub => sub._id === id);
            return subCategory ? { value: id, label: subCategory.name } : { value: id, label: "Unknown SubCategory" };
        });
    };
    const getCategoryNames = (ids) => {
        if (ids) {
            return ids.map(id => {
                const category = categories.find(cat => cat._id === id);
                return category ? { value: id, label: category.name } : { value: id, label: "Unknown Category" };
            });
        } else {
            return []
        }
    };

    const createMarkup = (htmlString) => {
        return { __html: decodedHtml(decodedHtml(htmlString)) };
    };

    const updateTopic = async (e) => {
        e.preventDefault();
        setMessage("");
        setErr("");
        // console.log(topic);
        const subCategoryIds = selectedTopicTags.map(tag => { return tag.value });
        const categoryIds = selectedCategoryTags.map(tag => { return tag.value });
        if (
            subCategoryIds.length === 0
            || categoryIds.length === 0
        ) {
            setErr("Please fill all the fields");
            return;
        }

        try {
            const token = Cookies.get("token");
            const p = {
                subCategoryIds,
                topicId: selectedTopic._id
            };
            const q = {
                categoryIds,
                topicId: selectedTopic._id
            }
            // console.log(p);
            if (Cookies.get("token")) {
                const response1 = await axios.patch(
                    "https://api.reviseukmla.com/api/v1/category/addRefSubCategory",
                    p,
                    {
                        headers: {
                            authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                const response2 = await axios.patch(
                    "https://api.reviseukmla.com/api/v1/category/addRefCategory",
                    q,
                    {
                        headers: {
                            authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response1.status === 200) {
                    setMessage("SubCategory updated successfully");
                    getTopics(selectedSubCategory._id);
                } else {
                    setErr("Failed to update SubCategory");
                }
                if (response2.status === 200) {
                    setMessage("Category updated successfully");
                    getTopics(selectedSubCategory._id);
                } else {
                    setErr("Failed to update Category");
                }

                if (response1.status === 200 && response2.status === 200) {
                    setMessage("Categories and subcategories updated successfully");
                    getTopics(selectedSubCategory._id);
                }
            } else {
                setErr("Please Login to update topics");
            }
        } catch (error) {
            setErr(error.message);
        }
    }

    if (selectedSubCategory !== null) {
        // getTopics(selectedSubCategory._id);
        return (
            <div className="topics-main container mt-4">
                <h3 style={{ display: "inline-block", float: "left" }}>SubCategory: {selectedSubCategory.name}<br />Topics</h3>
                <button
                    className="navigate-question next-question"
                    onClick={() => { setSelectedSubCategory(null); setMessage(""); setErr(""); setSelectedQuestions([]); }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </button>
                <Table bordered striped hover>
                    <thead>
                        <tr>
                            <th>Topic</th>
                            <th>Subcategories</th>
                            <th>Categories</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {topics.length > 0 ? topics.map((topic) => (
                            <tr key={topic._id} onClick={() => { setSelectedTopic(topic); setSelectedTopicTags(getSubCategoryNames(topic.subCategoryId)); setSelectedCategoryTags(getCategoryNames(topic.categoryId)); getQuestions(topic._id) }}>
                                <td><strong>{decodedHtml(topic.name)}</strong></td>
                                <td>{getSubCategoryNames(topic.subCategoryId).map(s => (
                                    <span style={{ fontSize: "1rem", margin: "5px 5px 0 0" }} key={s.value} className="badge text-bg-secondary">{decodedHtml(s.label)}</span>
                                ))}</td>
                                <td>{getCategoryNames(topic.categoryId).map(s => (
                                    <span style={{ fontSize: "1rem", margin: "5px 5px 0 0" }} key={s.value} className="badge text-bg-secondary">{decodedHtml(s.label)}</span>
                                ))}</td>
                                <td>
                                    <button
                                        className="navigate-question next-question" style={{ width: "80px", height: "35px", padding: "5px", margin: "0", backgroundColor: "red", borderColor: "red" }}
                                        onClick={() => dropTopic(topic._id)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        )) : <tr><td colSpan={categories.length + 1}>No topics found</td></tr>}
                    </tbody>
                </Table>
                {selectedTopic &&
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Add Sub-Categories To&nbsp;<strong>{decodedHtml(selectedTopic.name)}</strong></Accordion.Header>
                            <Accordion.Body>
                                {(topics.length !== 0) ? <ReactTags
                                    tags={selectedTopicTags}
                                    selected={selectedTopicTags}
                                    suggestions={subCategories.map(sub => ({ value: sub._id, label: decodedHtml(sub.name) }))}
                                    onDelete={handleTagDelete}
                                    onAdd={handleTagAddition}
                                    placeholderText="Add Sub Category"
                                /> : null}
                                <br />
                                {(topics.length !== 0) ? <ReactTags
                                    tags={selectedCategoryTags}
                                    selected={selectedCategoryTags}
                                    suggestions={categories.map(cat => ({ value: cat._id, label: decodedHtml(cat.name) }))}
                                    onDelete={handleCategoryTagsDelete}
                                    onAdd={handleCategoryTagsAddition}
                                    placeholderText="Add Category"
                                /> : null}
                                <Button
                                    onClick={updateTopic}
                                    className="btn btn-primary mt-3"
                                    variant="primary"
                                    type="submit"
                                >
                                    Update Topic
                                </Button>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Questions for&nbsp;<strong>{decodedHtml(selectedTopic.name)}</strong></Accordion.Header>
                            <Accordion.Body>
                                {selectedQuestions.length > 0 ? <div>
                                    {selectedQuestions.map((question) => (
                                        <div>
                                            <div style={{ whiteSpace: "nowrap", width: "100%", overflow: "hidden", textOverflow: "ellipsis" }} dangerouslySetInnerHTML={createMarkup(question.statement)}></div>
                                            <button
                                                className="navigate-question next-question" style={{ width: "35px", height: "35px", padding: "0", margin: "0", backgroundColor: (question.active ? "red" : "green"), borderColor: (question.active ? "red" : "green") }}
                                                onClick={() => { if (question.active) { deactivateQuestion(question._id) } else { activateQuestion(question._id) } }}
                                            >
                                                {question.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-check2" viewBox="0 0 16 16">
                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                                                    </svg>
                                                )}
                                            </button>
                                            {question.answers.map((answer, index) => (<span style={{ whiteSpace: "nowrap", width: "14%", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block" }}>{index + 1}. {answer.isCorrect === true ? (<span>✔️</span>) : (<span>❌</span>)}{answer.answer}</span>))}
                                        </div>
                                    ))}
                                </div> : <Alert variant="danger">No Questions for this Topic</Alert>}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Essay for&nbsp;<strong>{decodedHtml(selectedTopic.name)}</strong></Accordion.Header>
                            <Accordion.Body>
                                <div className="card-body topic-essay" dangerouslySetInnerHTML={createMarkup(selectedTopic.description)}></div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                }
                <AddTopic categories={categories} selectedSubCategory={selectedSubCategory} getTopics={getTopics} />

                {err && <Alert variant="danger">{err}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
            </div>
        )
    }

    return (
        <div className="sub-categories">
            {err && <Alert variant="danger">{err}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
            <Table striped hover id="table" className="sub-categories-table">
                <thead>
                    <tr>
                        <th><span className="subCategory" style={{ width: "10px" }}>#</span></th>
                        <th><span className="subCategory">SubCategories \ Categories</span></th>
                        {categories.map((category) => (
                            <th key={category._id}><span className="subCategory">{category.name}<span className="tooltiptext th">{decodedHtml(category.name)}</span></span></th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {subCategories.map((subCategory, idx) => (
                        <tr key={subCategory._id}>
                            <td><span className="subCategory sub-cat-name" style={{ width: "20px" }}>{idx + 1}<span className="tooltiptext" style={{ left: "20px" }}>{idx + 1}</span></span></td>
                            <td className="sub-cat-name" onClick={() => { setSelectedSubCategory(subCategory); getTopics(subCategory._id); setSelectedTopic(null); setSelectedTopicTags([]) }}><span className="subCategory">{decodedHtml(subCategory.name)}<span className="tooltiptext">{decodedHtml(subCategory.name)}</span></span></td>
                            {categories.map((category) => (
                                <td
                                    key={category._id}
                                    onClick={() => toggleDropdown(subCategory._id, category._id)}
                                    style={{ cursor: 'pointer', position: 'relative' }}
                                ><span className="subCategory">
                                        {subCategory.categoryId.includes(category._id) ? '✔️' : '-'}
                                        {dropdownVisible[`${subCategory._id}-${category._id}`] && (
                                            <Dropdown.Menu show style={{ position: 'absolute', top: '100%', left: 0 }}>
                                                <Dropdown.Item onClick={() => handleSelect(subCategory._id, category._id, 'add')}>✔️ Add</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleSelect(subCategory._id, category._id, 'remove')}>- Remove</Dropdown.Item>
                                            </Dropdown.Menu>
                                        )}</span>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default SubCategories;
