import React, { useState, useRef } from 'react'

import axios from 'axios';
import { Button, Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

function TrialQuiz() {
    const [err, setErr] = useState('');
    const [loadingQuiz, setLoadingQuiz] = useState(false);
    const navigate = useNavigate();
    const startTrialQuiz = async () => {
        setLoadingQuiz(true);
        try {
            const response = await axios.get("https://api.reviseukmla.com/api/v1/quiz/trialQuiz");

            if (response.status === 200) {
                localStorage.setItem("submittedAnswers", JSON.stringify(response.data.data.questions.map((question) => 0)));
                localStorage.setItem("selectedOptions", JSON.stringify(response.data.data.questions.map((question) => null)));
                localStorage.setItem("correctAnswers", JSON.stringify(response.data.data.questions.map((question) => null)));
                localStorage.setItem("topics", JSON.stringify(response.data.data.questions.map((question) => null)));
                localStorage.setItem("countdownDuration", 0);
                // Store questions in local storage
                localStorage.setItem("questions", JSON.stringify(response.data.data.questions));
                // Navigate to quiz page
                console.log(response.data.data.questions);
                navigate("/quiz/#quiz");
            } else {
                setErr("Failed to start quiz");
            }
        } catch (err) {
            // console.log(err);
            setErr(err.message);
        }
        setLoadingQuiz(false);
    }
    return (
        <>
            <Button variant="primary" className='btn-primary btn custombutton mb-4 take-demo' onClick={startTrialQuiz}>
                Take a Demo
            </Button>
            {loadingQuiz && (
                <div className="spinner-border text-primary" role="status" style={{ marginLeft: "47%", marginTop: "20px" }}>
                    <span className="visually-hidden">Loading...</span>
                </div>
            )}
            {err && <Alert variant="danger">{err}</Alert>}
        </>
    )
}

export default TrialQuiz