import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./PasswordReset.css";

const PasswordReset = () => {
  const [err, setErr] = useState("");
  const [message, setMessage] = useState("");
  const [user, setUser] = useState({
    password: "",
    passwordConfirm: "",
  });
  const navigate = useNavigate();
  const { token } = useParams(); // Extract the token from the URL path

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    if (user.password === value) {
      setErr("");
    } else {
      setErr("Passwords do not match");
    }
  };
  const passwordReset = async (e) => {
    e.preventDefault();
    setMessage("");
    setErr("");

    const { password, passwordConfirm } = user;

    if (password === "" || passwordConfirm === "") {
      setErr("Please fill all the fields");
      return;
    }

    if (password !== passwordConfirm) {
      setErr("Passwords do not match");
      return;
    }

    try {
      const response = await axios.patch(
        `https://api.reviseukmla.com/api/v1/users/resetPassword/${token}`,
        {
          password,
          passwordConfirm,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setMessage("Password Reset successful. Please login.");
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      setErr(
        error.response.data.message || "An error occurred. Please try again."
      );
    }
  };

  return (
    <div>
      <Container className="resetPassword-main">
        <Row className="justify-content-md-center">
          <Col md={{ span: 6 }}>
            <h2>Reset Password</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={{ span: 6 }}>
            <Form>
              <Form.Group controlId="formBasicPassword" className="mb-3">
                <Form.Control
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  className="customform no-outline"
                  type="password"
                  placeholder="Password"
                />
                <Form.Control
                  name="passwordConfirm"
                  value={user.passwordConfirm}
                  onChange={handleChangePassword}
                  className="customform no-outline"
                  type="password"
                  placeholder="Confirm Password"
                />
                <div className="text-center pt-3">
                  <Button
                    className="custombutton mb-3"
                    type="submit"
                    onClick={passwordReset}
                  >
                    Reset Password
                  </Button>
                  {err && (
                    <Alert key="danger" variant="danger">
                      {err}
                    </Alert>
                  )}
                  {message && (
                    <Alert key="success" variant="success">
                      {message}
                    </Alert>
                  )}
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PasswordReset;
