import { UserContext } from "../../Contexts/UserProvider";
import React, { useState, useEffect, useRef, useMemo, useContext, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./AddQuestion.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Form, Button, Alert } from "react-bootstrap";
import { ReactTags } from 'react-tag-autocomplete';

function AddQuestion() {
  const [err, setErr] = useState("");
  const [message, setMessage] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const { user } = useContext(UserContext);
  const navigate = useNavigate()
  const [question, setQuestion] = useState({
    statement: "",
    choices: ["", "", "", ""],
    correctAnswer: "",
    subcategories: [],
    explanation: "",
    topic: []
  });

  const quillRef = useRef(null);

  useEffect(() => {
    if (user.role !== "admin") {
      navigate(-1);
      return;
    }
    getSubCategories();
  }, []);

  const getSubCategories = async () => {
    const token = Cookies.get("token");
    const response = await axios.get(
      `https://api.reviseukmla.com/api/v1/category/getSubCategories`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const sub_categories = await response.data.data.subCategories.map((sub_category) => { return { value: sub_category._id, label: sub_category.name } });
    setSubCategories(sub_categories);
    // console.log(sub_categories);
  };
  const getTopics = async (subCategory) => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(
        `https://api.reviseukmla.com/api/v1/category/getTopics/?subCategory=${subCategory}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const topicsData = response.data.data.topics.map((topic) => ({
        value: topic._id,
        label: topic.name,
      }));

      setTopics(topicsData);
    } catch (error) {
      setErr("Error fetching topics");
      // console.error("Error fetching topics:", error);
      // Handle the error appropriately here
      // For example, you might want to show a notification to the user
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const fileArray = Array.from(files);
      setQuestion((prev) => ({
        ...prev,
        [name]: fileArray,
      }));
    } else {
      setQuestion((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleChoiceChange = (e) => {
    const { name, value } = e.target;
    const choiceIndex = parseInt(name.replace("choice", ""));
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      choices: prevQuestion.choices.map((choice, index) =>
        index === choiceIndex ? value : choice
      ),
    }));
  };

  const handleStatementChange = (value) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      statement: value,
    }));
  };

  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("image", file);
      console.log(file);
      const token = Cookies.get("token");
      const response = await axios.post(
        "https://api.reviseukmla.com/api/v1/image/upload-image",
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const url = response.data.imageUrl;
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", url);
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],

          [
            { indent: "-1" },
            { indent: "+1" }
          ],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["link", "image", "video"],
          ["clean"],
        ],

        handlers: {
          image: handleImageUpload,
        },
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
      },
    }),
    []
  );


  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ]

  const submitHandler = async (e) => {
    e.preventDefault();
    setMessage("");
    setErr("");
    console.log(question);
    const { statement, choices, correctAnswer, subcategories, explanation, topic } = question;

    if (
      statement === "" ||
      choices[0] === "" ||
      choices[1] === "" ||
      choices[2] === "" ||
      choices[3] === "" ||
      correctAnswer === "" ||
      subcategories.length === 0 ||
      explanation === "" ||
      topic === ""
    ) {
      setErr("Please fill all the fields");
      return;
    }

    try {
      const token = Cookies.get("token");
      const p = {
        statement,
        answers: [
          {
            answer: correctAnswer,
            isCorrect: true,
          },
          {
            answer: choices[0],
            isCorrect: false,
          },
          {
            answer: choices[1],
            isCorrect: false,
          },
          {
            answer: choices[2],
            isCorrect: false,
          },
          {
            answer: choices[3],
            isCorrect: false,
          },
        ],
        subcategories: subcategories.map((sub_category) => sub_category.value),
        explanation,
        topic: topic[0].value
      };
      console.log(p);
      if (Cookies.get("token")) {
        const response = await axios.post(
          "https://api.reviseukmla.com/api/v1/quiz/addQuestion",
          p,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 201) {
          setMessage("Question added successfully");
        } else {
          setErr("Failed to add question");
        }
      } else {
        setErr("Failed to add question");
      }
    } catch (error) {
      setErr(error.message);
    }
  };

  const handleSubCatAddition = async (newTag) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      subcategories: [newTag],
      topic: []
    }));
    // console.log(question.subcategories);
    setTopics([]);
    getTopics(newTag.value);
  };

  const handleSubCatDelete = (i) => {
    setQuestion((prevQuestion) => {
      return {
        ...prevQuestion,
        subcategories: [],
        topic: []
      };
    });
    setTopics([]);
  };
  const handleTopicAddition = useCallback((newTag) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      topic: [newTag],
    }));
    // console.log(question.subcategories);
  }, []);

  const handleTopicDelete = (i) => {
    setQuestion((prevQuestion) => {
      return {
        ...prevQuestion,
        topic: [],
      };
    });
  };

  return (
    <div>
      <div className="customtitle">
        <h3>Add Question</h3>
      </div>
      <div className="customCol">
        <Form className="customform">
          <Form.Group className="customforms">
            <Form.Label>Question</Form.Label>
            <div className="quill-editor">
              <ReactQuill
                modules={modules}
                formats={formats}
                value={question.statement}
                onChange={handleStatementChange}
                ref={quillRef}
                placeholder="Enter Question"
              ></ReactQuill>
            </div>
            <Form.Label>
              <b>Choices</b>
            </Form.Label>
            <br />
            <Form.Label>Correct Answer</Form.Label>
            <Form.Control
              name="correctAnswer"
              value={question.correctAnswer}
              onChange={handleChange}
              className="customform"
              as="textarea"
              placeholder="Enter Correct Answer"
            />
            <Form.Label>Choice # 1</Form.Label>
            <Form.Control
              name="choice0"
              value={question.choices[0]}
              onChange={handleChoiceChange}
              className="customform"
              as="textarea"
              placeholder="Enter Choice # 1"
            />
            <Form.Label>Choice # 2</Form.Label>
            <Form.Control
              name="choice1"
              value={question.choices[1]}
              onChange={handleChoiceChange}
              className="customform"
              as="textarea"
              placeholder="Enter Choice # 2"
            />
            <Form.Label>Choice # 3</Form.Label>
            <Form.Control
              name="choice2"
              value={question.choices[2]}
              onChange={handleChoiceChange}
              className="customform"
              as="textarea"
              placeholder="Enter Choice # 3"
            />
            <Form.Label>Choice # 4</Form.Label>
            <Form.Control
              name="choice3"
              value={question.choices[3]}
              onChange={handleChoiceChange}
              className="customform"
              as="textarea"
              placeholder="Enter Choice # 4"
            />
            <Form.Label>Explanation</Form.Label>
            <Form.Control
              name="explanation"
              value={question.explanation}
              onChange={handleChange}
              className="customform"
              as="textarea"
              placeholder="Enter Explanation"
            />
            <Form.Label>Sub Category</Form.Label>
            {(subCategories.length) ? <ReactTags
              tags={question.subcategories}
              selected={question.subcategories}
              suggestions={subCategories}
              onDelete={handleSubCatDelete}
              onAdd={handleSubCatAddition}
              placeholderText="Add subcategories"
            /> : null}
            <br />
            {(topics.length !== 0) ? <ReactTags
              tags={question.topic}
              selected={question.topic}
              suggestions={topics}
              onDelete={handleTopicDelete}
              onAdd={handleTopicAddition}
              placeholderText="Add Topic"
            /> : null}
          </Form.Group>
          <Button
            onClick={submitHandler}
            className="custombutton1"
            variant="primary"
            type="submit"
          >
            Add Question
          </Button>
          {err !== "" ? (
            <Alert key="danger" variant="danger">
              {err}
            </Alert>
          ) : null}
          {message !== "" ? (
            <Alert key="success" variant="success">
              {message}
            </Alert>
          ) : null}
        </Form>
      </div>
    </div>
  );
}

export default AddQuestion;
