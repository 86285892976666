import React, { useState, useEffect, useContext } from "react";
import "./Header.css";
import { UserContext } from "../../Contexts/UserProvider";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import Cookies from "js-cookie";

const Header = (props) => {
  const { user, loading } = useContext(UserContext);
  const [expanded, setExpanded] = useState(props.expanded);

  const handleToggle = (e) => {
    setExpanded(e);
    // console.log(expanded);
  };

  const logout = () => {
    Cookies.remove("token");
    localStorage.removeItem("dataLoaded");
    localStorage.removeItem("user");
    localStorage.removeItem("questions");
    localStorage.removeItem("selectedOptions");
    localStorage.removeItem("submittedAnswers");
    localStorage.removeItem("topics");
    localStorage.removeItem("timerStart");
    localStorage.removeItem("countdownDuration");
    window.location.href = "/login";
  };
  return (
    <div>
      <Navbar bg="black" variant="dark" expand={false} expanded={props.expanded} className="main-header" onToggle={setExpanded}>
        <Container>
          <Navbar.Brand href="/">
            <h1>ReviseUKMLA</h1>
          </Navbar.Brand>
          <div className="menu-right">
            {!user ? (
              <span>
                <Button href="/login">Login</Button>
              </span>
            ) : (
              <Button href="/" onClick={logout}>
                Logout
              </Button>
            )}
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            <button
              className=""
              style={{ border: "none", backgroundColor: "transparent" }}
              type="button"
              aria-controls="basic-navbar-nav"
              aria-label="Toggle navigation"
            >
              <label className="hamburger">
                <input type="checkbox" checked={props.expanded} onClick={() => { props.handleToggle(!props.expanded); }} />
                <svg viewBox="0 0 32 32" >
                  <path className="line line-top-bottom" d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
                  <path className="line" d="M7 16 27 16"></path>
                </svg>
              </label>
            </button>
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className="menu-right">
            <Nav className="ml-auto">
              {(user && user.role === "admin") && (
                <React.Fragment>
                  <Nav.Link href="/addQuestion">Add Question</Nav.Link>
                  <Nav.Link href="/addSubCategory">Add Sub-Category</Nav.Link>
                  <Nav.Link href="/SubCategories">Sub-Categories</Nav.Link>
                </React.Fragment>
              )}
              {(user && user.subscription) ? (
                <React.Fragment>
                  <Nav.Link href="/subscriptions">My Subscription</Nav.Link>
                  <Nav.Link href="/performance">Your Performance</Nav.Link>
                </React.Fragment>
              ) : (
                <span>
                  <Nav.Link href="/subscriptions">Subscriptions</Nav.Link>
                </span>
              )}
              <Nav.Link href="/jewels">Jewels</Nav.Link>
              <Nav.Link href="/flashcards">Flashcards</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="header-line"></div>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
