import { UserContext } from "../../Contexts/UserProvider";
import React, { useState, useEffect, useRef, useMemo, useContext, useCallback } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./AddSubCategory.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Form, Button, Alert } from "react-bootstrap";
import { ReactTags } from 'react-tag-autocomplete';


function AddSubCategory() {
  const [err, setErr] = useState("");
  const [message, setMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [subCategory, setSubCategory] = useState({
    name: "",
    description: "",
    categoryId: []
  });

  const quillRef = useRef(null);

  useEffect(() => {
    if (user.role !== "admin") {
      navigate(-1);
      return;
    }
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    const token = Cookies.get("token");
    const response = await axios.get(
      `https://api.reviseukmla.com/api/v1/category/getAllCategories`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const categories = await response.data.data.categories.map((category) => { return { value: category._id, label: category.name } });
    setCategories(categories);
    // console.log(categories);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const fileArray = Array.from(files);
      setSubCategory((prev) => ({
        ...prev,
        [name]: fileArray,
      }));
    } else {
      setSubCategory((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("image", file);
      // console.log(file);
      const token = Cookies.get("token");
      const response = await axios.post(
        "https://api.reviseukmla.com/api/v1/quiz/upload-image",
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const url = response.data.imageUrl;
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", url);
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["link", "image"],
          ["table"],
          ["clean"],
        ],
        handlers: {
          image: handleImageUpload,
        },
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
        clipboard: {
          matchVisual: false,
        },
      },
    }),
    []
  );


  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  const submitHandler = async (e) => {
    e.preventDefault();
    setMessage("");
    setErr("");
    // console.log(question);
    const { name, description, categoryId } = subCategory;

    if (
      name === "" ||
      description === "" ||
      categoryId.length === 0
    ) {
      setErr("Please fill all the fields");
      return;
    }

    try {
      const token = Cookies.get("token");
      const p = {
        name,
        description,
        categoryId: categoryId.map((category) => category.value)
      };
      // console.log(p);
      if (Cookies.get("token")) {
        const response = await axios.post(
          "https://api.reviseukmla.com/api/v1/category/addSubCategory",
          p,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 201) {
          setMessage("Sub Category added successfully");
        } else {
          setErr("Failed to add Sub Category");
        }
      } else {
        setErr("Failed to add Sub Category");
      }
    } catch (error) {
      setErr(error.message);
    }
  };

  const handleTagAddition = useCallback((newTag) => {
    setSubCategory((prevSubCategory) => ({
      ...prevSubCategory,
      categoryId: [...prevSubCategory.categoryId, newTag],
    }));
    // console.log(question.subcategories);
  }, []);

  const handleTagDelete = (i) => {
    setSubCategory((prevSubCategory) => {
      const newTags = prevSubCategory.categoryId.slice(0);
      newTags.splice(i, 1);
      return {
        ...prevSubCategory,
        categoryId: newTags,
      };
    });
  };
  const handleEssayChange = (value) => {
    setSubCategory((prevQuestion) => ({
      ...prevQuestion,
      description: value,
    }));
  };

  return (
    <div>
      <div className="customtitle">
        <h3>Add Sub Category</h3>
      </div>
      <div className="customCol">
        <Form className="customform">
          <Form.Group className="customforms">
            <Form.Label>Sub Category</Form.Label>
            <Form.Control
              name="name"
              value={subCategory.name}
              onChange={handleChange}
              className="customform"
              as="textarea"
              placeholder="Enter Sub Category Name"
            />
            <br />
            <Form.Label>Essay
            </Form.Label>
            <div className="quill-editor">
              <ReactQuill
                modules={modules}
                formats={formats}
                value={subCategory.description}
                onChange={handleEssayChange}
                ref={quillRef}
                placeholder="Enter Essay of Sub Category"
                theme="snow"
              ></ReactQuill>
            </div>
            <br />
            <Form.Label>Category</Form.Label>
            {categories && <ReactTags
              tags={subCategory.categoryId}
              selected={subCategory.categoryId}
              suggestions={categories}
              onDelete={handleTagDelete}
              onAdd={handleTagAddition}
              placeholderText="Add Categories"
            />}
          </Form.Group>
          <Button
            onClick={submitHandler}
            className="custombutton1"
            variant="primary"
            type="submit"
          >
            Add Sub Category
          </Button>
          {err !== "" ? (
            <Alert key="danger" variant="danger">
              {err}
            </Alert>
          ) : null}
          {message !== "" ? (
            <Alert key="success" variant="success">
              {message}
            </Alert>
          ) : null}
        </Form>
      </div>
    </div>
  );
}

export default AddSubCategory;
