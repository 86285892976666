import React from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink } from 'react-bootstrap';
import './Footer.css'

function Footer() {
  return (
    <footer className="footer-container">
      <Container>
        <Row>
          <Col md={7} className='flex-center'>
            {/* <h5>About</h5> */}
            <p>Preparing IMGs for UK(MLA) - old PLAB 1 and UK medical students for finals</p>
          </Col>
          <Col md={2} style={{ textAlign: "right" }}>
            <Nav>
              <NavItem>
                <NavLink href="/jewels">Jewels</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/flashcards">Flashcards</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about">About us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact">Contact us</NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md={3} style={{ textAlign: "right" }}>
            <Nav>
              <NavItem>
                <NavLink href="/privacy-policy">Privacy Policy</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/cookie-policy">Cookie Policy</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/terms-and-conditions">Terms and Conditions</NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <p>© 2024 UKMLA • All rights reserved.</p>
      </Container>
    </footer>
  );
}

export default Footer;