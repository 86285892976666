import React, { useState, useRef, useMemo, useCallback } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import axios from "axios";
import Cookies from "js-cookie";
import { Form, Button, Alert } from "react-bootstrap";
import { ReactTags } from 'react-tag-autocomplete';

function AddTopic(props) {
    const [err, setErr] = useState("");
    const [message, setMessage] = useState("");
    const [categories, setCategories] = useState(props.categories);
    const [selectedCategoryTags, setSelectedCategoryTags] = useState([]);
    const [topic, setTopic] = useState({
        name: "",
        description: "",
        categoryId: []
    });
    const quillRef = useRef(null);
    const submitHandler = async (e) => {
        e.preventDefault();
        setMessage("");
        setErr("");
        // console.log(topic);
        const { name, description } = topic;

        if (
            name === "" ||
            description === ""
        ) {
            setErr("Please fill all the fields");
            return;
        }

        try {
            const token = Cookies.get("token");
            const p = {
                name,
                subCategoryId: props.selectedSubCategory._id,
                description,
                categoryId: selectedCategoryTags.map(tag => { return tag.value })
            };
            console.log(p);
            if (Cookies.get("token")) {
                const response = await axios.post(
                    "https://api.reviseukmla.com/api/v1/category/addTopic",
                    p,
                    {
                        headers: {
                            authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.status === 201) {
                    setMessage("Topic added successfully");
                    props.getTopics(props.selectedSubCategory._id);
                    setTopic({
                        name: "",
                        description: ""
                    });
                    setSelectedCategoryTags([]);
                } else {
                    setErr("Failed to add question");
                }
            } else {
                setErr("Please Login to add topic");
            }
        } catch (error) {
            setErr(error.message);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file") {
            const fileArray = Array.from(files);
            setTopic((prev) => ({
                ...prev,
                [name]: fileArray,
            }));
        } else {
            setTopic((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleImageUpload = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            const formData = new FormData();
            formData.append("image", file);
            // console.log(file);
            const token = Cookies.get("token");
            const response = await axios.post(
                "https://api.reviseukmla.com/api/v1/quiz/upload-image",
                formData,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            const url = response.data.imageUrl;
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            quill.insertEmbed(range.index, "image", url);
        };
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ font: [] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ direction: "rtl" }],
                    [{ size: ["small", false, "large", "huge"] }],
                    ["link", "image"],
                    ["clean"],
                ],
                handlers: {
                    image: handleImageUpload,
                },
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true,
                },
                clipboard: {
                    matchVisual: false,
                },
            },
        }),
        []
    );


    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];
    const handleCategoryTagsAddition = useCallback((newTag) => {
        setSelectedCategoryTags([...selectedCategoryTags, newTag]);
    }, [selectedCategoryTags]);

    const handleCategoryTagsDelete = (i) => {
        const updatedTagList = selectedCategoryTags.filter((_, index) => index !== i);
        setSelectedCategoryTags(updatedTagList);
    };
    const handleEssayChange = (value) => {
        setTopic((prevTopic) => ({
            ...prevTopic,
            description: value,
        }));
    };

    function decodedHtml(html) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
    }
    return (
        <div>
            <Form className="customform">
                <Form.Group className="customforms">
                    <Form.Label>Topic Name</Form.Label>
                    <Form.Control
                        name="name"
                        value={topic.name}
                        onChange={handleChange}
                        className="customform"
                        as="textarea"
                        placeholder="Enter Topic Name"
                    />
                    <br />
                    <Form.Label>Essay</Form.Label>
                    <div className="quill-editor">
                        <ReactQuill
                            modules={modules}
                            formats={formats}
                            value={topic.description}
                            onChange={handleEssayChange}
                            ref={quillRef}
                            placeholder="Enter Essay of Topic"
                            theme="snow"
                        ></ReactQuill>
                    </div>
                    <Form.Label>Categories</Form.Label>
                    <ReactTags
                        tags={selectedCategoryTags}
                        selected={selectedCategoryTags}
                        suggestions={categories.map(cat => ({ value: cat._id, label: decodedHtml(cat.name) }))}
                        onDelete={handleCategoryTagsDelete}
                        onAdd={handleCategoryTagsAddition}
                        placeholderText="Add Category"
                    />
                    <br />
                </Form.Group>
                <Button
                    onClick={submitHandler}
                    className="custombutton1"
                    variant="primary"
                    type="submit"
                >
                    Add Topic
                </Button>
            </Form>
            {err && <Alert variant="danger">{err}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
        </div>
    )
}

export default AddTopic