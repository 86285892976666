import React from 'react'
import { Row, Col } from 'react-bootstrap'

function NotFound() {
    return (
        <div className='home-main align-items-center' style={{ height: "80vh" }}>
            <div className='container'>
                <Row className="justify-content-center mt-4">
                    <Col md={12} className="flex-center">
                        <h2 className="white-heading" style={{ fontSize: "60px" }}><strong>404 Not Found :(</strong></h2><br />
                    </Col>
                </Row>
            </div>
        </div >
    )
}

export default NotFound